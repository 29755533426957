import React from 'react';
import { Table } from 'antd';
import { Wrapper } from '~/shared/elements';
import { stringCompare } from '~/lib/utils/stringUtils';
import './Compliance.scss';

const complianceDocumentColumns = [
  {
    title: 'File Name',
    dataIndex: 'fileName',
    sorter: (a, b) => {
      const [, ...aRestFileName] = a.fileName.split('_');
      const [, ...bRestFileName] = b.fileName.split('_');
      return stringCompare(aRestFileName.join(''), bRestFileName.join(''));
    },
    render: (fileName, { link }) => {
      const [, ...restFileName] = fileName.split('_');
      return (
        <a href={link} target="_blank" rel="noreferrer">
          {restFileName.join('')}
        </a>
      );
    }
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    sorter: (a, b) =>
      new Date(parseInt(a.createdAt, 10)) - new Date(parseInt(b.createdAt, 10)),
    render: createdAt => new Date(parseInt(createdAt, 10)).toLocaleDateString()
  }
];

const Compliance = ({ complianceDocuments = [] }) => {
  return (
    <Wrapper className="page compliance">
      <div className="floating-section compliance">
        <h3>Compliance</h3>
        <Table
          dataSource={complianceDocuments}
          columns={complianceDocumentColumns}
          rowKey={({ id }) => id}
          rowClassName="row"
          bordered
          size="small"
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText: 'There are no Compliance Documents found.'
          }}
        />
      </div>
    </Wrapper>
  );
};

export default Compliance;
