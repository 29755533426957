import React, { useState, useEffect } from 'react';
import { Table, Row, Col } from 'antd';
import SideMenu from '~/shared/page-coms/SideMenu';
import { Wrapper } from '~/shared/elements';
import Support from './com/Support';
import Billing from './com/Billing';
import './Account.scss';

const nessusScanDetailsColumns = [
  {
    title: 'Severity Level',
    dataIndex: 'severity',
    render: severity => SEVERITY_MAP[severity]
  },
  {
    title: 'Account Category',
    dataIndex: 'category'
  },
  {
    title: 'Account Name',
    dataIndex: 'name'
  },
  {
    title: 'Count',
    dataIndex: 'count'
  }
];

const ROUTES = [
  {
    key: 'support',
    title: 'Support'
  },
  {
    key: 'billing',
    title: 'Billing'
  }
];

const Account = ({ client }) => {
  const [active, setActive] = useState('support');

  const sectionSwitch = () => {
    switch (active) {
      case 'billing':
        return (
          <>
            <h2>Billing</h2>
            <Billing client={client} />
          </>
        );
      case 'support':
      default:
        return (
          <>
            <h2>Company Information</h2>
            <Support client={client} />
          </>
        );
    }
  };

  return (
    <Wrapper className="page account">
      <div className="floating-section">
        <h3>Account</h3>
        <Row style={{ marginLeft: '5px' }}>
          <SideMenu
            handleSelect={selection => setActive(selection)}
            routes={ROUTES}
            selectedKeys={[active]}
          />
          <Col lg={{ span: 18 }} md={{ span: 16 }} sm={{ span: 24 }}>
            {sectionSwitch()}
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
};

export default Account;
