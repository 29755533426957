import React, { useState, useEffect } from 'react';
import { Table, Select } from 'antd';
import { useGlobal } from 'reactn';

import './PhishingCampaigns.scss';

const { Option } = Select;

const instanceResultColumns = usersObj => [
  {
    title: 'Name',
    dataIndex: 'user',
    render: ({ first_name, last_name }) => `${first_name} ${last_name}`
  },
  {
    title: 'Location',
    dataIndex: 'ip_location'
  },
  {
    title: 'Vulnerability Level',
    dataIndex: ['user', 'id'],
    key: 'vulnerability',
    render: userId => {
      const currentRiskScore = usersObj[userId].current_risk_score;
      const { color, level } =
        currentRiskScore <= 20
          ? { color: '#00d100', level: 'Low' }
          : currentRiskScore <= 40
          ? { color: '#f2fd00', level: 'Medium Low' }
          : currentRiskScore <= 60
          ? { color: '#f4c300', level: 'Medium' }
          : currentRiskScore <= 80
          ? { color: '#ff6900', level: 'Medium High' }
          : { color: '#ee3733', level: 'High' };
      return (
        <div>
          {currentRiskScore} - <span style={{ color }}>{level}</span>
        </div>
      );
    }
  },
  {
    title: 'Department',
    dataIndex: ['user', 'id'],
    render: userId => usersObj[userId].department
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (
      _,
      { attachment_opened_at, reported_at, replied_at, data_entered_at, opened_at }
    ) => (
      <div className="bubbles">
        {!!attachment_opened_at && <div className="bubble attachment-opened" />}
        {!!opened_at && <div className="bubble opened" />}
        {!!replied_at && <div className="bubble replied" />}
        {!!data_entered_at && <div className="bubble data-entered" />}
        {!!reported_at && <div className="bubble reported" />}
      </div>
    )
  }
];

const PhishingCampaigns = ({ phishingCampaigns = [], users = [] }) => {
  const [loading, setLoading] = useState(false);
  const [currentClientUser] = useGlobal('currentClientUser');
  const { knowBeforeKey } = currentClientUser?.client ?? {};

  const [selectedCampaign, setSelectedCampaign] = useState('');
  const [selectedCampaignInstance, setSelectedCampaignInstance] = useState('');
  const [instanceResults, setInstanceResults] = useState([]);

  const { psts = [] } =
    phishingCampaigns.find(({ campaign_id }) => campaign_id === selectedCampaign) || {};

  const usersObj = users.reduce(
    (a, { id, ...rest }) => ({ ...a, [id]: { id, ...rest } }),
    {}
  );

  useEffect(() => {
    const getInstanceResults = async () => {
      setLoading(true);
      const res = await fetch(
        `https://us.api.knowbe4.com/v1/phishing/security_tests/${selectedCampaignInstance}/recipients`,
        {
          headers: {
            Authorization: `Bearer: ${knowBeforeKey}`,
            'Content-Type': 'application/json'
          }
        }
      ).then(r => r.json());
      setLoading(false);
      if (res) setInstanceResults(res);
    };

    if (!!selectedCampaignInstance && !!knowBeforeKey) getInstanceResults();
  }, [selectedCampaignInstance]);

  return (
    <div className="phishing-campaigns floating-section">
      <div className="flex">
        <h3>Employees</h3>
        <div className="legend">
          <div className="key">
            <div className="bubble data-entered" />
            Entered Data
          </div>
          <div className="key">
            <div className="bubble attachment-opened" />
            Attachment Opened
          </div>
          <div className="key">
            <div className="bubble replied" />
            Replied
          </div>
          <div className="key">
            <div className="bubble opened" />
            Opened
          </div>
          <div className="key">
            <div className="bubble reported" />
            Reported Phishing Email
          </div>
        </div>
      </div>
      <label>
        Campaign:{' '}
        <Select onChange={val => setSelectedCampaign(val)} style={{ width: '200px' }}>
          {phishingCampaigns.map(({ campaign_id, name }) => (
            <Option key={campaign_id} value={campaign_id}>
              {name}
            </Option>
          ))}
        </Select>
      </label>
      <label style={{ marginLeft: '20px' }}>
        Campaign Instance:{' '}
        <Select
          onChange={val => setSelectedCampaignInstance(val)}
          style={{ width: '200px' }}
        >
          {psts.map(({ pst_id, start_date }) => (
            <Option key={pst_id} value={pst_id}>
              {start_date ? new Date(start_date).toLocaleDateString() : ''}
            </Option>
          ))}
        </Select>
      </label>
      <Table
        dataSource={instanceResults}
        columns={instanceResultColumns(usersObj)}
        rowKey={({ recipient_id }) => recipient_id}
        rowClassName="row"
        bordered
        size="small"
        scroll={{ x: 'max-content' }}
        locale={{
          emptyText: 'There are no Campaign Results found.'
        }}
        loading={loading}
      />
    </div>
  );
};

export default PhishingCampaigns;
