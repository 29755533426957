import React from 'react';
import { Table } from 'antd';
import './Support.scss';

const managementColumns = [
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Title',
    dataIndex: 'title'
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone'
  },
  {
    title: 'Email',
    dataIndex: 'email'
  }
];

const Support = ({ client }) => {
  const { team } = client;
  const { teamLead, name: teamName } = team || {};
  const { phone: teamLeadPhone, email: teamLeadEmail } = teamLead || {};
  return (
    <div className="support">
      <h4>Support</h4>
      833-CINGO-IT <br /> <br />
      support@cingo.solutions <br />
      {teamName.replace(' ', '').toLowerCase()}@cingo.solutions <br />
      <h4>Support Team Leader</h4>
      {teamLeadEmail} <br />
      {teamLeadPhone} <br />
      <h4>Management Contacts</h4>
      <Table
        dataSource={[
          // {
          //   name: 'Josh Zitting',
          //   title: 'Operations Admin',
          //   phone: '(123) 456-7890',
          //   email: 'josh.zitting@cingo.solutions'
          // },
          {
            name: 'Tony Madsen',
            title: 'Operations Admin',
            phone: '(123) 456-7890',
            email: 'tony.madsen@cingo.solutions'
          }
        ]}
        columns={managementColumns}
        rowKey={({ name }) => name}
        rowClassName="row"
        size="small"
        scroll={{ x: 'max-content' }}
        locale={{
          emptyText: 'There are no Contacts found.'
        }}
      />
    </div>
  );
};

export default Support;
