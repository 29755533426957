import React from 'react';
import { Button } from 'antd';

const MyButton = ({ children, type = 'default', style = {}, ...rest }) => {
  const defaultStyle = {
    color: type === 'primary' ? 'white' : '',
    borderColor: type === 'primary' ? 'var(--orange)' : '',
    backgroundColor: type === 'primary' ? 'var(--orange)' : '',
    textTransform: 'uppercase',
    fontSize: rest.size === 'large' ? '14px' : ''
  };

  return (
    <Button style={{ ...defaultStyle, ...style }} type={type} {...rest}>
      {children}
    </Button>
  );
};

export default MyButton;
