import React, { useState } from 'react';
import { Switch, Button } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import './FixedFooter.scss';

const FixedFooter = ({ status, setSelectedTicket }) => {
  return (
    <div className="fixed-footer">
      {status}

      <div className="footer-buttons">
        <Button type="primary" block onClick={() => setSelectedTicket(false)}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default FixedFooter;
