import { object, string } from 'yup';

export const PasswordResetValidation = () =>
  object().shape({
    email: string()
      .email('Invalid email')
      .required('Email is required')
  });

export const PasswordResetDefaults = () => ({
  email: ''
});

export const resetValidationSchema = object().shape({
  newPassword: string()
    .required('New password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
    ),
  confirmPassword: string().required('Please confirm password')
});

export const resetDefaults = { newPassword: '', confirmPassword: '' };
