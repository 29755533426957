import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Row, Col, Button } from 'antd';
import { Form, Formik } from 'formik';
import { Select, Input } from '~/shared/forms';
import { states } from '~/lib/static/selectorFields';
import {
  createCreditCardPaymentValidation,
  createCreditCardPaymentDefaults
} from './CreateCreditCardPayment.validation';
import alert from '~/shared/elements/Notification';

const CreateCreditCardPayment = ({ addCreditCardPaymentMethod, onCreate }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handlePaymentCreate = async (values, actions) => {
    const { setSubmitting, resetForm } = actions;
    const { paymentName, name, email, line1, state, zipcode, city } = values;
    const billingDetails = {
      name,
      email,
      address: {
        city,
        line1,
        state,
        postal_code: zipcode
      }
    };
    const cardElement = elements.getElement(CardElement);

    const paymentMethodReq = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: billingDetails
    });
    const { error, paymentMethod } = paymentMethodReq;

    if (error) {
      alert('Error', `${error.message}`, 8, 'error', 'topRight');
      setSubmitting(false);
      return;
    }
    try {
      const creditCardAttributes = {
        paymentName,
        token: paymentMethod.id,
        lastFour: paymentMethod.card.last4
      };
      const {
        data: { addCreditCardPaymentMethod: newPaymentMethod }
      } = await addCreditCardPaymentMethod({
        variables: { creditCardAttributes }
      });
      onCreate(newPaymentMethod);
      alert('Success', 'Payment method created.', 4, 'success', 'topRight');
      resetForm();
      cardElement.clear();
    } catch (e) {
      // console.log('e', e);
    }
    setSubmitting(false);
  };
  const cardElementOptions = {
    hidePostalCode: true
  };
  return (
    <Formik
      initialValues={createCreditCardPaymentDefaults()}
      validationSchema={createCreditCardPaymentValidation()}
      onSubmit={handlePaymentCreate}
    >
      {({ isSubmitting }) => {
        return (
          <Form className="credit-card-form">
            <Row>
              <Input
                type="text"
                name="paymentName"
                label="Payment Name"
                placeholder="Payment Name"
              />
              <Col span={12}>
                <Input type="text" name="name" label="Name" placeholder="Name" />
                <Input type="text" name="email" label="Email" placeholder="Email" />
                <Input type="text" name="line1" label="Address" placeholder="Address" />
              </Col>
              <Col offset={1} span={11}>
                <Input type="text" name="city" label="City" placeholder="City" />
                <Select name="state" label="State" options={states} />
                <Input
                  type="text"
                  name="zipCode"
                  label="Zip Code"
                  placeholder="Zip Code"
                />
              </Col>
            </Row>
            <CardElement options={cardElementOptions} />

            <Button
              htmlType="submit"
              type="primary"
              disabled={isSubmitting}
              className="right-flushed-link-button"
              style={{ marginLeft: 'auto', display: 'block', marginTop: '10px' }}
            >
              Create
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateCreditCardPayment;
