import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import ApiError from '~/shared/errors/ApiError';
import { Spinner } from '~/shared/elements';
import PaymentMethods from './PaymentMethods';

const REMOVE_PAYMENT_METHOD = gql`
  mutation($paymentId: ID!) {
    removePaymentMethod(paymentId: $paymentId)
  }
`;

const ADD_BANK_ACCOUNT_PAYMENT_METHOD = gql`
  mutation($publicToken: String!, $achBankAccount: AchBankAccount!) {
    addBankAccountPaymentMethod(
      publicToken: $publicToken
      achBankAccount: $achBankAccount
    ) {
      id
      status
      lastFour
      name
      paymentType
      scheduledPayment
    }
  }
`;

const SET_DEFAULT_PAYMENT = gql`
  mutation($paymentId: ID!) {
    setDefaultPayment(paymentId: $paymentId) {
      id
      status
      lastFour
      name
      paymentType
      scheduledPayment
    }
  }
`;

const PLAID_PUBLIC_KEY = gql`
  {
    plaidLinkToken
    plaidEnv
  }
`;

const PaymentMethodsData = props => {
  const { data, error, loading } = useQuery(PLAID_PUBLIC_KEY);

  const [removePaymentMethod] = useMutation(REMOVE_PAYMENT_METHOD);
  const [setDefaultPayment] = useMutation(SET_DEFAULT_PAYMENT);
  const [addBankAccountPaymentMethod] = useMutation(ADD_BANK_ACCOUNT_PAYMENT_METHOD);
  if (loading) return <Spinner />;
  if (error) return <ApiError error={error} />;

  return (
    <PaymentMethods
      {...props}
      removePaymentMethod={removePaymentMethod}
      setDefaultPayment={setDefaultPayment}
      addBankAccountPaymentMethod={addBankAccountPaymentMethod}
      data={data}
    />
  );
};

export default PaymentMethodsData;
