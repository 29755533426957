import React from 'react';
import { Field } from 'formik';
import { Radio } from 'antd';

// Ant design radio button.
const AntRadio = ({ field, form, label, ...rest }) => (
  <Radio.Button {...field} {...rest} checked={form.values[field.name] === field.value}>
    {label}
  </Radio.Button>
);

// Formik radio input wrapper.
const FormikRadio = props => <Field type="radio" component={AntRadio} {...props} />;

export default FormikRadio;
