import React from 'react';

const SubHeader = ({ children, style, ...rest }) => {
  return (
    <h2 style={{ ...style }} {...rest}>
      {children}
    </h2>
  );
};

export default SubHeader;
