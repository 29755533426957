import React from 'react';
import { Divider } from 'antd';

const defaultStyle = {
  fontSize: '3rem',
  lineHeight: '5rem',
  fontWeigth: 100,
  margin: 0
};

const MyHeader = ({ children, style, ...rest }) => (
  <Divider orientation="left" style={{ padding: '3rem 0', margin: 0 }}>
    <h1 style={{ ...defaultStyle, ...style }} {...rest}>
      {children}
    </h1>
  </Divider>
);

export default MyHeader;
