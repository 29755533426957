import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Spinner } from '~/shared/elements';
import ApiError from '~/shared/errors/ApiError';
import Compliance from './Compliance';

const COMPLIANCE_DOCUMENTS = gql`
  {
    ownComplianceDocuments {
      id
      fileName
      createdAt
      link
    }
  }
`;

const ComplianceData = props => {
  const { data, error, loading } = useQuery(COMPLIANCE_DOCUMENTS);

  if (loading) return <Spinner />;
  if (error) return <ApiError error={error} />;

  const { ownComplianceDocuments } = data;

  return <Compliance complianceDocuments={ownComplianceDocuments} {...props} />;
};

export default ComplianceData;
