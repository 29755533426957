import { object, string } from 'yup';
import { emailRegExp } from '~/lib/utils/validationUtils';

export const signInValidation = object().shape({
  email: string()
    .matches(emailRegExp, 'Email is not valid.')
    .required('Email is required.'),
  password: string().required('Password is required.')
});

export const signInDefaults = {
  email: '',
  password: ''
};
