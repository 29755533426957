import React from 'react';
import { Field } from 'formik';
import { Upload, Form, Button, Icon } from 'antd';

const { Item } = Form;

// Sets the color of warning: 'success', 'warning', 'error', 'validating'
const status = 'warning';

const AntUpload = ({
  field,
  form,
  label,
  buttonText = 'Upload Document',
  multiple = false,
  ...rest
}) => {
  const errorExistsAndFieldTouched =
    !!form.errors[field.name] && !!form.touched[field.name];

  return (
    <Item
      label={label}
      validateStatus={errorExistsAndFieldTouched ? status : null}
      help={errorExistsAndFieldTouched ? form.errors[field.name] : null}
      hasFeedback
    >
      <Upload
        {...field}
        onRemove={async file =>
          form.setFieldValue(
            field.name,
            (form.values[field.name] || []).filter(({ uid }) => file.uid !== uid)
          )
        }
        onChange={async ({ fileList, file: { size } }) => {
          if (size < 12000000) {
            multiple
              ? form.setFieldValue(field.name, fileList)
              : form.setFieldValue(field.name, fileList.length ? [fileList[0]] : []);
          } else {
            form.setErrors({ [field.name]: '12 MB size limit' });
          }
        }}
        fileList={form.values[field.name] ? form.values[field.name] : []}
        customRequest={({ onSuccess }) => setTimeout(() => onSuccess('ok'), 0)}
        {...rest}
        showUploadList={{ showDownloadIcon: false }}
        multiple
      >
        <Button>
          <Icon type="upload" /> {buttonText}
        </Button>
      </Upload>
    </Item>
  );
};

const FormikUpload = props => <Field component={AntUpload} {...props} />;

export default FormikUpload;
