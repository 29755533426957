import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';
import { setContext } from 'apollo-link-context';
import { ApolloLink, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import alert from '~/shared/elements/Notification';

const httpLink = createUploadLink({
  uri: window.location.host.includes('localhost')
    ? 'http://localhost:3000/api/graphql'
    : '/api/graphql',
  credentials: 'include'
});

const wsLink = new WebSocketLink({
  uri: window.location.host.includes('localhost')
    ? 'ws://localhost:3000/api/graphql'
    : `wss://${window.location.host}/api/graphql`,
  options: {
    reconnect: true
  }
});

const middlewareLink = setContext(() => ({
  headers: {}
}));

// eslint-disable-next-line
const errorLink = setLoggedIn =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      // graphQLErrors.forEach(({ message, locations, path }) => {
      const {
        message,
        locations,
        extensions: { exception: { stacktrace = [] } = {} } = {}
      } = graphQLErrors[0];
      if (message.includes('Not authorized')) {
        alert('Error', message.substring(0, 50), 4, 'warning');
        setLoggedIn(false);
      } else if (message.includes('PersistedQueryNotFound')) {
        // eslint-disable-next-line
        console.log(
          '%cFirst time making request, persisted query failed.',
          'color: green; border-radius: 3px; border: 1px solid green; display: block;'
        );
      } else {
        // eslint-disable-next-line
        console.error(
          `[GraphQL error] \n` +
            `Message: ${message} \n` +
            `Location: ${JSON.stringify(locations)} \n` +
            `Trace: ${stacktrace[0]} ${stacktrace[1]}`
        );
        alert('Error', message.substring(0, 50), 4, 'warning');
      }
      // });
    }

    // eslint-disable-next-line
    if (networkError) console.error(`[Network error]: ${networkError}`);
  });

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(res => {
    // const { errors } = res;
    // const { response } = operation.getContext();
    // const { headers } = response;
    // if (headers) {
    //   const token = headers.get('x-token');
    //   const refreshToken = headers.get('x-refresh-token');

    //   if (token) {
    //     localStorage.setItem('AUTH_TOKEN', token);
    //   }

    //   if (refreshToken) {
    //     localStorage.setItem('REFRESH_TOKEN', refreshToken);
    //   }
    // }
    // if (errors && errors.length) {
    //   if (errors.map(({ message }) => message).includes('User token has expired.'))
    //     signOut();
    // }

    return res;
  });
});

const link = split(
  ({ query: { definitions } }) =>
    definitions.some(
      ({ kind, operation }) =>
        kind === 'OperationDefinition' && operation === 'subscription'
    ),
  wsLink,
  httpLink
);

export default function(setLoggedIn) {
  const linkWithMiddleware = ApolloLink.from([
    errorLink(setLoggedIn),
    createPersistedQueryLink(),
    afterwareLink,
    middlewareLink,
    link
  ]);

  return new ApolloClient({
    link: linkWithMiddleware,
    cache: new InMemoryCache()
  });
}
