import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Spinner from '~/shared/elements/Spinner';
import ApiError from '~/shared/errors/ApiError';
import TicketDetail from './TicketDetail';
import { TICKET_FRAGMENT } from '~/lib/graphql/fragments';

const TICKET = gql`
  query clientTicket($ticketId: ID!) {
    clientTicket(ticketId: $ticketId) {
      ...TICKET_FRAGMENT
    }
  }
  ${TICKET_FRAGMENT}
`;

const TicketDetailData = ({ selectedTicket, ...props }) => {
  const { data, loading, error } = useQuery(TICKET, {
    fetchPolicy: 'network-only',
    variables: { ticketId: selectedTicket },
    skip: !selectedTicket
  });

  if (loading) return <Spinner />;
  if (error) return <ApiError error={error} />;

  return <TicketDetail ticket={data ? data.clientTicket : selectedTicket} {...props} />;
};

export default TicketDetailData;
