import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import BillingStatuses from './BillingStatuses';
import alert from '~/shared/elements/Notification';

const START_BILLING = gql`
  mutation {
    startBilling {
      id
      billingStatus
      licenseQuantity
      invoices {
        id
        amountDue
        amountPaid
        invoiceNumber
        status
        link
      }
      activePaymentMethods {
        id
        status
        lastFour
        name
        paymentType
        scheduledPayment
      }
    }
  }
`;

const CURRENT_USER_AND_ROLES = gql`
  query currentClientUser {
    currentClientUser {
      id
      firstName
      lastName
      roles
      userRoles {
        id
        role
      }
      client {
        id
        knowBeforeKey
        stripeInfo {
          billingStatus
        }
      }
    }
  }
`;

const BillingStatusesData = props => {
  // Automatically updates the cache to give the user access to the site
  const [startBilling] = useMutation(START_BILLING);

  return <BillingStatuses {...props} startBilling={startBilling} />;
};

export default BillingStatusesData;
