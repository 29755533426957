import React from 'react';
import { Header } from '~/shared/elements';

const SmallHeader = ({ children }) => (
  <Header
    style={{
      fontSize: '2rem',
      lineHeight: '3rem'
    }}
  >
    {children}
  </Header>
);

export default SmallHeader;
