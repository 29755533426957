import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Spinner } from '~/shared/elements';
import ApiError from '~/shared/errors/ApiError';
import AppRiver from './AppRiver';

const CLIENT = gql`
  {
    selfClient {
      id
      appRiverWeeklyStats {
        id
        weekDate
        validEmails
        spamEmails
        virusEmails
        totalEmails
      }
    }
  }
`;

const AppRiverData = props => {
  const { data, error, loading } = useQuery(CLIENT);

  if (loading) return <Spinner />;
  if (error) return <ApiError error={error} />;

  return <AppRiver client={data.selfClient} {...props} />;
};

export default AppRiverData;
