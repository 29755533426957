import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import SignIn from '~/pages/SignIn';
import Terms from '~/pages/Terms';
import Privacy from '~/pages/Privacy';

const ROUTES = [
  {
    path: '/',
    component: SignIn
  },
  {
    path: '/reset-password',
    component: SignIn
  },
  {
    path: '/terms',
    component: Terms
  },
  {
    path: '/privacy',
    component: Privacy
  }
];

const PublicRoutes = () => (
  <Route
    render={({ location }) => {
      const { pathname } = location;
      const routeMatch = ROUTES.find(({ path }) => path === pathname);
      return routeMatch ? (
        <Route exact path={pathname} component={routeMatch.component} />
      ) : (
        <Redirect to={{ pathname: '/' }} />
      );
    }}
  />
);

export default PublicRoutes;
