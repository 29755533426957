import React, { useState, useEffect, useRef } from 'react';
import { Table, Tabs, Select, DatePicker, Radio, Button } from 'antd';
import { StopOutlined, CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { Pie } from 'ant-design-pro/lib/Charts';
import html2canvas from 'html2canvas';
import { jsPDF as JSPDF } from 'jspdf';
import moment from 'moment';
import alert from '~/shared/elements/Notification';
import download from '~/lib/utils/download';
import { toCsvString } from '~/lib/utils/csv';
import './DNSFilter.scss';

const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const trafficReportColumns = totalCount => [
  {
    title: 'Domain Name',
    dataIndex: 'domain'
  },
  {
    title: 'Categories',
    dataIndex: 'categories',
    render: categories => categories.join(', ')
  },
  {
    title: 'Request',
    dataIndex: 'total',
    render: total => `${total}(${((total / totalCount) * 100).toFixed(2)}%)`
  }
];

const DNSFilter = ({
  loadTrafficReports,
  topDomainsLoading,
  dnsFilterTopDomains,
  dnsFilterTopCategories,
  dnsFilterTotalRequests,
  dnsFilterNetworks,
  dnsFilterUserAgents,
  totalTopDomains,
  totalCount,
  domainTicketsCreate
}) => {
  const graphsRef = useRef(null);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [requestType, setRequestType] = useState('Threats Total');
  const [investigateFilter, setInvestigateFilter] = useState('request');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: totalTopDomains
  });
  const [startDate, setStartDate] = useState(moment().subtract(1, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedUserAgent, setSelectedUserAgent] = useState('');

  const {
    totalCount: totalCategoryRequests,
    records: dnsFilterTopCategoryRecords = []
  } = dnsFilterTopCategories;

  useEffect(() => {
    const formattedStartDate = new Date(
      new Date(startDate).setHours(0, 0, 0, 0)
    ).toISOString();
    const formattedEndDate = new Date(
      new Date(endDate).setHours(23, 59, 59, 999)
    ).toISOString();
    loadTrafficReports({
      variables: {
        params: {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          networkId: selectedLocation || undefined,
          agentId: selectedUserAgent || undefined,
          type: requestType === 'Investigate Total' ? 'allowed' : undefined,
          securityReport:
            requestType === 'Threats Total' ||
            (requestType === 'Investigate Total' && investigateFilter === 'threat'),
          page: pagination?.current,
          pageSize: pagination?.pageSize ?? 10,
          categoryId:
            requestType === 'Investigate Total' && investigateFilter === 'request'
              ? '-1'
              : ''
        }
      }
    });
  }, [
    startDate,
    endDate,
    selectedLocation,
    selectedUserAgent,
    pagination,
    requestType,
    investigateFilter
  ]);

  useEffect(() => {
    setPagination({ ...pagination, total: totalTopDomains });
  }, [totalTopDomains]);

  const submitDomainTicketsCreate = async allow => {
    try {
      await domainTicketsCreate({
        variables: {
          domains: selectedDomains,
          allow
        }
      });
      alert('Created', 'Tickets Created Successfully', 4, 'success', 'topRight');
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="floating-section dns-filter">
      <div className="flex-options">
        <div className="threat-select">
          <Radio.Group
            options={[
              { label: 'Threats Total', value: 'Threats Total' },
              { label: 'Investigate Total', value: 'Investigate Total' },
              { label: 'Top Request', value: 'Top Request' }
            ]}
            onChange={({ target: { value } }) => setRequestType(value)}
            value={requestType}
            optionType="button"
            buttonStyle="solid"
          />
        </div>
        <div>Total Request: {dnsFilterTotalRequests}</div>
      </div>
      <div className="flex-options">
        <Select
          onChange={val => setSelectedLocation(val)}
          placeholder="All Locations"
          allowClear
        >
          {dnsFilterNetworks.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>

        <Select
          onChange={val => setSelectedUserAgent(val)}
          placeholder="All Workstations"
          allowClear
        >
          {dnsFilterUserAgents.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>

        <RangePicker
          defaultValue={[startDate, endDate]}
          onChange={([start, end]) => {
            setStartDate(start.toString());
            setEndDate(end.toString());
          }}
        />
      </div>
      <Tabs
        defaultActiveKey="1"
        onChange={key => {
          console.log('key', key);
          if (key === '1')
            setPagination({
              current: 1,
              pageSize: 10,
              total: totalTopDomains
            });
        }}
      >
        <TabPane tab="Graph" key="1">
          <Button
            className="download-button"
            type="link"
            onClick={async () => {
              const canvas = await html2canvas(graphsRef.current);
              const imgData = canvas.toDataURL('image/jpeg', 1.0);
              const pdf = new JSPDF();
              const imgProps = pdf.getImageProperties(imgData);
              const width = pdf.internal.pageSize.getWidth();
              const height = (imgProps.height * (width - 20)) / imgProps.width;
              pdf.addImage(imgData, 'JPEG', 20, 20, width - 20, height);
              pdf.save('domain_security.pdf');
            }}
          >
            <DownloadOutlined /> PDF
          </Button>
          <div className="request-graphs" ref={graphsRef}>
            <div className="request-graph">
              <h4>
                {requestType === 'Threats Total'
                  ? 'Threatening Categories'
                  : 'Top Categories'}
              </h4>
              <Pie
                hasLegend
                // title="title"
                subTitle={
                  requestType === 'Threats Total' ? 'Threats Blocked' : 'Total Requests'
                }
                total={() => parseInt(totalCategoryRequests).toLocaleString()}
                data={dnsFilterTopCategoryRecords
                  .map(({ categories: [category], total }) => ({
                    x: category || 'Uncategorized',
                    y: total
                  }))
                  .slice(0, 5)}
                height={294}
              />
            </div>
            <div className="request-graph">
              <h4>
                {requestType === 'Threats Total' ? 'Threatening Domains' : 'Top Domains'}
              </h4>
              <Pie
                hasLegend
                // title="title"
                subTitle={
                  requestType === 'Threats Total' ? 'Threats Blocked' : 'Total Requests'
                }
                total={() => parseInt(totalCount).toLocaleString()}
                data={dnsFilterTopDomains
                  .map(({ domain, total }) => ({
                    x: domain,
                    y: total
                  }))
                  .slice(0, 5)}
                height={294}
              />
            </div>
          </div>
        </TabPane>
        <TabPane tab={requestType === 'Investigate Total' ? 'Actions' : 'Logs'} key="2">
          {requestType === 'Investigate Total' && (
            <Radio.Group
              className="request-threat"
              value={investigateFilter}
              onChange={({ target: { value } }) => setInvestigateFilter(value)}
              style={{ marginTop: '16px' }}
            >
              <Radio.Button value="request">Request</Radio.Button>
              <Radio.Button value="threat">Threat</Radio.Button>
            </Radio.Group>
          )}
          <div className="table-options">
            {requestType === 'Investigate Total' && (
              <div className="allow-block">
                <Button
                  type="link"
                  className="block"
                  onClick={() => submitDomainTicketsCreate(false)}
                  disabled={!selectedDomains.length}
                >
                  <StopOutlined /> Block
                </Button>
                <Button
                  type="link"
                  className="allow"
                  onClick={() => submitDomainTicketsCreate(true)}
                  disabled={!selectedDomains.length}
                >
                  <CheckCircleOutlined /> Allow
                </Button>
              </div>
            )}
            <Button
              className="download-button"
              type="link"
              onClick={() =>
                download(
                  `domain_security.csv`,
                  toCsvString([
                    ['Domain Name', 'Categories', 'Request'],
                    ...dnsFilterTopDomains.map(({ domain, categories, total }) => [
                      domain,
                      categories,
                      `${total}(${((total / totalCount) * 100).toFixed(2)}%)`
                    ])
                  ])
                )
              }
            >
              <DownloadOutlined /> CSV
            </Button>
          </div>
          <Table
            dataSource={dnsFilterTopDomains}
            columns={trafficReportColumns(totalCount)}
            rowKey={({ domain }) => domain}
            rowClassName="row"
            size="small"
            scroll={{ x: 'max-content' }}
            locale={{
              emptyText: 'There are no Traffic Reports found.'
            }}
            loading={topDomainsLoading}
            pagination={pagination}
            onChange={newPagination =>
              setPagination({ ...newPagination, total: totalTopDomains })
            }
            rowSelection={
              requestType === 'Investigate Total'
                ? {
                    onSelect: ({ domain }) => {
                      !selectedDomains.includes(domain)
                        ? setSelectedDomains([...selectedDomains, domain])
                        : setSelectedDomains(
                            selectedDomains.filter(domainId => domainId !== domain)
                          );
                    },
                    selectedRowKeys: selectedDomains,
                    onSelectAll: checked =>
                      checked
                        ? setSelectedDomains(
                            dnsFilterTopDomains.map(({ domain }) => domain)
                          )
                        : setSelectedDomains([])
                  }
                : undefined
            }
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default DNSFilter;
