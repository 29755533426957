const s = {
  // year: 31536000,
  // month: 2592000,
  // week: 604800,
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1
};

export const timeDiffWithSeconds = (dateFuture, dateNow) => {
  let d = Math.abs(dateFuture - dateNow) / 1000;
  const r = {};
  Object.keys(s).forEach(key => {
    r[key] = Math.floor(d / s[key]);
    d -= r[key] * s[key];
  });
  return `${r.hour}:${r.minute}:${r.second}`;
};

export default function(dateFuture, dateNow) {
  let d = Math.abs(dateFuture - dateNow) / 1000;
  const r = {};
  Object.keys(s).forEach(key => {
    r[key] = Math.floor(d / s[key]);
    d -= r[key] * s[key];
  });
  return `${r.day}:${r.hour}:${r.minute}`;
}
