import React, { useEffect } from 'react';
import { addCallback } from 'reactn';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Spinner from '~/shared/elements/Spinner';
import ErrorBoundary from '~/shared/errors/ErrorBoundary';
import App from './App';

const ENV_SERVER = window.location.origin.includes('localhost')
  ? 'http://localhost:3000'
  : window.location.origin;

const CURRENT_USER = gql`
  query currentClientUserAndRoles {
    currentClientUser {
      id
      firstName
      lastName
      roles
      userRoles {
        id
        role
      }
      client {
        id
        knowBeforeKey
        stripeInfo {
          billingStatus
        }
      }
    }
  }
`;

const AppData = ({ apolloClient }) => {
  useEffect(() => {
    addCallback(async ({ loggedIn, roles, currentClientUser }) => {
      if (!loggedIn && (!!roles.length || !!currentClientUser)) {
        await fetch(`${ENV_SERVER}/api/logout`, { credentials: 'include' });
        await apolloClient.resetStore();
        return {
          roles: [],
          loggedIn: false,
          currentClientUser: null,
          serverUrl: ENV_SERVER
        };
      }
      return null;
    });
  }, []);

  const { error, loading, data, refetch } = useQuery(CURRENT_USER, {
    fetchPolicy: 'network-only'
  });

  if (loading) return <Spinner />;
  if (error) return <ErrorBoundary error={error} />;

  return <App foundCurrentClientUser={data.currentClientUser} refetch={refetch} />;
};

export default AppData;
