export function humanizeCameCase(str) {
  return (
    str
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, s => {
        return s.toUpperCase();
      })
  );
}

export const stringCompare = (stringA, stringB) => {
  const a = stringA || '';
  const b = stringB || '';
  if (a.toLowerCase() > b.toLowerCase()) {
    return 1;
  }
  if (a.toLowerCase() < b.toLowerCase()) {
    return -1;
  }
  return 0;
};

// Should be able to handle date strings, millisecond, date objects, undefined
export const dateCompare = (dateA, dateB) => {
  const dateObjA = new Date(!dateA ? null : dateA);
  const dateObjB = new Date(!dateB ? null : dateB);

  if (dateObjA > dateObjB) return 1;
  if (dateObjA < dateObjB) return -1;
  return 0;
};

export const toTitleCase = text =>
  text
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
    .split('_')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');

export default humanizeCameCase;
