import React, { useState } from 'react';
import { useDispatch, setGlobal, addReducer } from 'reactn';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import ErrorBoundary from '~/shared/errors/ErrorBoundary';
import apolloClientFunc from './Apollo.utils';
import App from './App';

const ENV_SERVER = window.location.origin.includes('localhost')
  ? 'http://localhost:3000'
  : window.location.origin;

addReducer('setLoggedIn', (_, __, loggedIn) => ({
  loggedIn
}));

setGlobal({
  roles: [],
  loggedIn: false,
  currentClientUser: null,
  serverUrl: ENV_SERVER
});

const ApolloSetup = () => {
  const dispatch = useDispatch();
  const [initiatedClient] = useState(apolloClientFunc(dispatch.setLoggedIn));
  return (
    <ErrorBoundary>
      <Router>
        <ApolloProvider client={initiatedClient}>
          <App apolloClient={initiatedClient} />
        </ApolloProvider>
      </Router>
    </ErrorBoundary>
  );
};

export default ApolloSetup;
