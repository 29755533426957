/* eslint-disable import/prefer-default-export */
import { useState, useEffect } from 'react';

export const useLocalStorageState = (initialValue, localStorageKey) => {
  const [state, setState] = useState(
    JSON.parse(localStorage.getItem(localStorageKey)) || initialValue
  );

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(state));
  }, [state]);
  return [state, setState];
};

export const useFetch = (url, options, { skip = false }) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (skip) return;
    const abortController = new AbortController();
    const { signal } = abortController;
    const doFetch = async () => {
      setLoading(true);
      try {
        const res = await fetch(url, options);
        const json = await res.json();
        if (!signal.aborted) {
          setResponse(json);
        }
      } catch (e) {
        if (!signal.aborted) {
          setError(e);
        }
      } finally {
        if (!signal.aborted) {
          setLoading(false);
        }
      }
    };
    doFetch();
    return () => {
      abortController.abort();
    };
  }, [skip]);
  return { data: response, error, loading };
};
export default useFetch;
