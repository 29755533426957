import React from 'react';
import { Avatar } from '~/shared/elements';

import './AssignedUser.scss';

const AssignedUser = ({ assignedUserName, image }) => {
  return (
    <div className="ticket-user">
      {assignedUserName}
      <Avatar image={image} />
    </div>
  );
};

export default AssignedUser;
