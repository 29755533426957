import moment from 'moment';

// REGULAR EXPRESSIONS
// eslint-disable-next-line no-useless-escape
const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
const emailRegExp = /\S+@\S+\.\S+/;
const stringIsIntRegExp = /^\d+$/;
const zipCodeRegExp = /^\d{5}$|^\d{5}-\d{4}$/;
const decimalRegExp = /^\d+(\.\d{1,2})?$/;

// Validates that query parameters are correct.
// Single Example: ?<key>=<value>
const queryParametersRegExp = /^\?\w+=\w+/;

// VALIDATION FUNCTIONS
const validateDateTime = (date, format) => moment(date, format).isValid();

const exists = value => value;
const notEmptyString = str => !!str.trim();

const existsAndNonEmpty = value => exists(value) && notEmptyString(value);

// Methods that find keys or values in Objects, Arrays, or Arrays of objects
const isAKeyOfObj = (value, data) => Object.keys(data).includes(value);
const isValueOfObj = (value, data) => {
  let isValid = false;
  const keys = Object.keys(data);

  keys.forEach(key => {
    if (data[key] === value) {
      isValid = true;
    }
  });
  return isValid;
};
const valueExistsInElementOfArray = (key, value, data) =>
  data.filter(element => element[key] === value).length;
const arrayIncludes = (arr, value) => arr.includes(value);

export {
  phoneRegExp,
  emailRegExp,
  validateDateTime,
  stringIsIntRegExp,
  zipCodeRegExp,
  decimalRegExp,
  queryParametersRegExp,
  isAKeyOfObj,
  isValueOfObj,
  exists,
  notEmptyString,
  existsAndNonEmpty,
  valueExistsInElementOfArray,
  arrayIncludes
};
