import React from 'react';
import './PasswordSecurity.scss';
import { Tooltip } from 'antd';

const PasswordSecurity = ({ clientUsers = [] }) => {
  const fifteenDaysFromNow = new Date(Date.now() + 1000 * 60 * 60 * 24 * 15);
  const thirtyDaysFromNow = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30);

  const exemptClientUsers = clientUsers.filter(
    ({ passwordNeverExpires }) => !!passwordNeverExpires
  ).length;
  const currentClientUsers = clientUsers.filter(({ passwordExpired }) => !passwordExpired)
    .length;
  const expiredClientUsers = clientUsers.filter(
    ({ passwordExpired }) => !!passwordExpired
  ).length;
  const expiringInLessThanFifteenClientUsers = clientUsers.filter(
    ({ passwordExpirationDate }) =>
      !!passwordExpirationDate && new Date(passwordExpirationDate) <= fifteenDaysFromNow
  ).length;
  const expiringInLessThanThirtyClientUsers = clientUsers.filter(
    ({ passwordExpirationDate }) =>
      !!passwordExpirationDate && new Date(passwordExpirationDate) <= thirtyDaysFromNow
  ).length;

  const exemptPercent = Math.round((exemptClientUsers / clientUsers.length) * 100);
  const currentPercent = Math.round((currentClientUsers / clientUsers.length) * 100);
  const expiredPercent = Math.round((expiredClientUsers / clientUsers.length) * 100);
  const fifteenPercent = Math.round(
    (expiringInLessThanFifteenClientUsers / clientUsers.length) * 100
  );
  const thirtyPercent = Math.round(
    (expiringInLessThanThirtyClientUsers / clientUsers.length) * 100
  );

  const counts = [
    {
      name: 'Exempted',
      color: '#ff0019',
      percent: exemptPercent,
      total: exemptClientUsers
    },
    {
      name: 'Expired',
      color: '#ff8c00',
      percent: expiredPercent,
      total: expiredClientUsers
    },
    {
      name: '15 Days',
      color: '#ffd600',
      percent: fifteenPercent,
      total: expiringInLessThanFifteenClientUsers
    },
    {
      name: '30 Days',
      color: '#00c800',
      percent: thirtyPercent,
      total: expiringInLessThanThirtyClientUsers
    },
    {
      name: 'Current',
      color: '#0384f1',
      percent: currentPercent,
      total: currentClientUsers
    }
  ];

  return (
    <div className="floating-section password-security">
      <h3>Password Security</h3>
      <div className="counts">
        {counts.map(({ name, color, percent, total }) => (
          <Tooltip key={name} title={`Total: ${total}`}>
            <div className="count">
              <span>{name}</span>{' '}
              <div className="percent-bar">
                <div
                  className="percent"
                  style={{ width: `${percent}%`, backgroundColor: color }}
                />
              </div>
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default PasswordSecurity;
