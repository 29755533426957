import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { TICKET_FRAGMENT } from '~/lib/graphql/fragments';
import Comments from './Comments';

const CREATE_EXTERNAL_COMMENT = gql`
  mutation createExternalComment($ticketId: ID!, $externalComment: String!) {
    createExternalComment(ticketId: $ticketId, externalComment: $externalComment) {
      ...TICKET_FRAGMENT
    }
  }
  ${TICKET_FRAGMENT}
`;

const CommentsData = props => {
  const [createExternalComment] = useMutation(CREATE_EXTERNAL_COMMENT);

  return <Comments createExternalComment={createExternalComment} {...props} />;
};

export default CommentsData;
