import React from 'react';
import { Field } from 'formik';
import { TimePicker, Form } from 'antd';

const { Item } = Form;

// Sets the color of warning: 'success', 'warning', 'error', 'validating'
const status = 'warning';

/* ...field passes it's own onChange. The order below over rides field.onChange but allows
for ...rest to over ride default */

const AntTimePicker = ({ field, form, label, ...rest }) => {
  const errorExistsAndFieldTouched =
    !!form.errors[field.name] && !!form.touched[field.name];

  return (
    <Item
      label={label}
      validateStatus={errorExistsAndFieldTouched ? status : null}
      help={errorExistsAndFieldTouched ? form.errors[field.name] : null}
    >
      <TimePicker
        {...field}
        onChange={time => form.setFieldValue(field.name, time)}
        {...rest}
      />
    </Item>
  );
};

const FormikTimePicker = props => <Field component={AntTimePicker} {...props} />;

export default FormikTimePicker;
