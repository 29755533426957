import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { DateRangeSlider } from '~/shared/elements';

const AppRiver = ({ client }) => {
  const { appRiverWeeklyStats: defaultAppRiverWeeklyStats } = client;
  const orderedDefaultAppRiverWeeklyStats = defaultAppRiverWeeklyStats.sort(
    (a, b) => new Date(a.weekDate) - new Date(b.weekDate)
  );
  const [appRiverWeeklyStats, setAppRiverWeeklyStats] = useState(
    orderedDefaultAppRiverWeeklyStats
  );
  const dateRangeEnd = moment(
    orderedDefaultAppRiverWeeklyStats[orderedDefaultAppRiverWeeklyStats.length - 1]
      ?.weekDate ?? new Date()
  )
    .startOf('day')
    .toJSON();
  const dateRangeStart = moment(
    orderedDefaultAppRiverWeeklyStats[0]?.weekDate ?? new Date(Date.now() - 1e9)
  )
    .startOf('day')
    .toJSON();

  const getDateRangeInitialValues = () => [dateRangeStart, dateRangeEnd];
  const [dateRangeValues, setDateRangeValues] = useState(getDateRangeInitialValues());

  const data = {
    labels: appRiverWeeklyStats.map(({ weekDate }) => weekDate),
    datasets: [
      {
        label: 'Virus',
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#FB0909',
        borderColor: '#FB0909',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#FB0909',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#FB0909',
        pointHoverBorderColor: '#FB0909',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: appRiverWeeklyStats.map(({ virusEmails }) => virusEmails),
        order: 0
      },
      {
        label: 'Threat',
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#FBB709',
        borderColor: '#FBB709',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#FBB709',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#FBB709',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: appRiverWeeklyStats.map(({ spamEmails }) => spamEmails),
        order: 1
      },
      {
        label: 'Valid',
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#52C41A',
        borderColor: '#52C41A',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#52C41A',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#52C41A',
        pointHoverBorderColor: '#52C41A',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: appRiverWeeklyStats.map(({ validEmails }) => validEmails),
        order: 2
      }
    ]
  };

  useEffect(() => {
    setAppRiverWeeklyStats(
      defaultAppRiverWeeklyStats
        .filter(
          ({ weekDate }) =>
            new Date(dateRangeValues[0]) <= new Date(weekDate) &&
            new Date(dateRangeValues[1]) >= new Date(weekDate)
        )
        .sort((a, b) => new Date(a.weekDate) - new Date(b.weekDate))
    );
  }, [dateRangeValues]);

  return (
    <div className="floating-section">
      <h3>Email Security Report</h3>
      <Line data={data} />
      {defaultAppRiverWeeklyStats.length > 1 && (
        <DateRangeSlider
          timeUnit="day"
          from={dateRangeStart}
          to={dateRangeEnd}
          values={dateRangeValues}
          onChange={setDateRangeValues}
        />
      )}
    </div>
  );
};

export default AppRiver;
