import React from 'react';
import { Col, Menu } from 'antd';

const SideMenu = ({ handleSelect, routes, selectedKeys, ...props }) => {
  return (
    <Col
      lg={{ span: 6 }}
      md={{ span: 8 }}
      sm={{ span: 24 }}
      style={{ paddingRight: '3rem' }}
    >
      <Menu
        mode="inline"
        onClick={e => handleSelect(e.key)}
        className="menu"
        style={{ userSelect: 'none' }}
        selectedKeys={selectedKeys}
        {...props}
      >
        {routes.map(route => (
          <Menu.Item
            key={route.key}
            disabled={route.disabled}
            data-test={route.testHookData || ''}
          >
            {route.title}
          </Menu.Item>
        ))}
      </Menu>
    </Col>
  );
};

export default SideMenu;
