import React from 'react';
import { Row, Col, Button, Select as AntSelect, Descriptions } from 'antd';
import { Form, Formik } from 'formik';
import money from '~/lib/utils/moneyUtils';
import { Select } from '~/shared/forms';
import {
  pastDuePaymentFormValidation,
  pastDuePaymentFormDefaults
} from './PastDuePaymentForm.validation';
import alert from '~/shared/elements/Notification';

const { Option } = AntSelect;
const { Item } = Descriptions;

const PastDuePaymentForm = ({
  onPay,
  duePayment,
  paymentMethods,
  manuallyPayPastDuePayment
}) => {
  const { createdAt, unpaidAmount } = duePayment;

  const handlePayPastDuePayment = async (values, actions) => {
    const { setSubmitting } = actions;

    try {
      const {
        data: { manuallyPayPastDuePayment: updatedSubscription }
      } = await manuallyPayPastDuePayment({
        variables: {
          delinquentPaymentAttributes: { ...values }
        }
      });

      alert('Success', 'Payment accepted!', 4, 'success', 'topRight');
      onPay(updatedSubscription);
    } catch (e) {
      // alert('Error', `${e.message}`, 4, 'error', 'topRight');
    }
    setSubmitting(false);
  };
  return (
    <Formik
      initialValues={pastDuePaymentFormDefaults(duePayment, paymentMethods)}
      validationSchema={pastDuePaymentFormValidation(paymentMethods)}
      onSubmit={handlePayPastDuePayment}
    >
      {({ isSubmitting }) => {
        return (
          <Form className="credit-card-form">
            <Row>
              <Col span={24}>
                <Descriptions>
                  <Item label="Due Date">{createdAt}</Item>
                  <Item label="Amount">{money(unpaidAmount * 100)}</Item>
                </Descriptions>
              </Col>

              <Select name="paymentMethodId" label="Payment Method">
                {paymentMethods.map(({ id, lastFour, name }) => (
                  <Option value={id} key={id}>
                    {`${name}: ending in ${lastFour}`}
                  </Option>
                ))}
              </Select>
            </Row>
            <p>
              * This payment method will be set as the default for all future payments.
            </p>
            <Button
              htmlType="submit"
              type="primary"
              disabled={isSubmitting}
              className="right-flushed-link-button"
            >
              Pay
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PastDuePaymentForm;
