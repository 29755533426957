import React from 'react';
import { Formik, Form } from 'formik';
import { Input } from '~/shared/forms';
import { Button } from '~/shared/elements';
import { RegisterValidation, RegisterDefaults } from './Register.validation';

const Register = ({ setSignInOption }) => {
  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(false);
  };
  return (
    <Formik
      validationSchema={RegisterValidation()}
      initialValues={RegisterDefaults()}
      validateOnBlur={false}
      onSubmit={handleSubmit}
      render={({ errors, isSubmitting }) => (
        <Form>
          <h2>Register</h2>
          <Input
            name="email"
            inputType="input"
            autoComplete="email"
            placeholder="Email address..."
            data-test="email"
          />
          <Input
            name="password"
            inputType="password"
            autoComplete="password"
            placeholder="Password..."
            data-test="password"
          />
          <Input
            name="confirmPassword"
            inputType="password"
            autoComplete="password"
            placeholder="Password..."
            data-test="password"
          />

          {errors.api && <div>{errors.api}</div>}

          <Button
            type="primary"
            htmlType="submit"
            disabled={isSubmitting}
            data-test="button-submit"
            shape="round"
            style={{
              margin: '10px auto 20px auto',
              width: '150px',
              height: '38px',
              textTransform: 'uppercase',
              borderColor: 'var(--lightBlue)',
              backgroundColor: 'var(--lightBlue)'
            }}
          >
            Register
          </Button>
          <Button type="link" onClick={() => setSignInOption('login')}>
            Login
          </Button>
        </Form>
      )}
    />
  );
};

export default Register;
