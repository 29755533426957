import React, { useState } from 'react';
import { Carousel } from 'antd';
import { Wrapper } from '~/shared/elements';
import backgroundLogo from '~/lib/images/logos/login-background-logo.svg';
import logo from '~/lib/images/logos/cingo-logo.svg';
import { Login, PasswordReset } from './com';
import './SignIn.scss';

const QUOTES = [
  {
    quote: `97% OF EXECUTIVES Are Moderately To Very Confident In Their Organization's Ability To Safeguard Data And Yet There Has Been A 160% INCREASE IN BREACHES Since 2018.`,
    author: 'NetDiligence Report'
  },
  {
    quote: `Cyber-crime now outpaces the Illegal Drug Trade annually!`,
    author: 'TechNews'
  },
  {
    quote: `It is 3x more costly to an organization to get hit with a (regulatory) fine than it is to actually spend the money, time and resources to adhere to the compliance mandates in the first place.`,
    author: 'Ponemon Institute & Globalscape'
  }
];

const SignIn = () => {
  const [signInOption, setSignInOption] = useState(
    window.location.pathname.includes('reset-password') ? 'password-reset' : 'login'
  );

  return (
    <Wrapper className="page sign-in">
      <div className="login-box">
        <div className="login-box-content">
          <img className="logo" src={logo} alt="logo" />
          {signInOption === 'login' && <Login setSignInOption={setSignInOption} />}
          {signInOption === 'password-reset' && (
            <PasswordReset setSignInOption={setSignInOption} />
          )}
        </div>
      </div>
      <div className="gradients-box">
        <div className="light-blue-poly" />
        <div className="dark-blue-poly">
          <img className="logo" src={backgroundLogo} alt="logo" />
          <Carousel
            className="carousel"
            autoplay
            dots={false}
            nextArrow
            autoplaySpeed={5000}
          >
            {QUOTES.map(({ quote, author }) => (
              <div className="carousel-item" key={quote}>
                <p>{quote}</p>
                <p className="author">- {author}</p>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </Wrapper>
  );
};

export default SignIn;
