import React from 'react';
import { Formik } from 'formik';
import { Tabs, Button, Input as AntdInput } from 'antd';
import { Input } from '~/shared/forms';
import alert from '~/shared/elements/Notification';
import { Avatar } from '~/shared/elements';
import {
  externalCommentValidation,
  externalCommentDefaults
} from './Comments.validation';
import './Comments.scss';

const { TextArea } = AntdInput;

const { TabPane } = Tabs;

const Comments = ({ ticket, externalComments = [], createExternalComment }) => {
  const { id: ticketId, summary } = ticket;
  const submitCreateExternalComment = async (values, actions) => {
    const { setSubmitting, resetForm } = actions;
    const { externalComment } = values;
    try {
      await createExternalComment({
        variables: {
          ticketId,
          externalComment
        }
      });
      alert('Created', 'Comment Created Successfully', 4, 'success', 'topRight');
      resetForm();
    } catch (e) {
      console.log(e);
    }
    setSubmitting(false);
  };

  return (
    <div className="ticket-summary-container">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Summary" key="1">
          <TextArea defaultValue={summary} disabled />
        </TabPane>
        <TabPane tab="Comments" key="3">
          {externalComments.map(({ id, comment, createdAt, user, clientUser }) => {
            let { name, image: commentImage } = user || {};
            if (!user && !!clientUser) name = clientUser.name;
            return (
              <div key={id} className="old-comment">
                <Avatar image={commentImage} />
                <div className="comment-data">
                  <span className="name">{name}</span>
                  <span className="created-date">
                    {new Date(parseInt(createdAt, 10)).toLocaleString()}
                  </span>
                  <span className="comment">{comment}</span>
                </div>
              </div>
            );
          })}
          <Formik
            initialValues={externalCommentDefaults}
            validationSchema={externalCommentValidation}
            onSubmit={submitCreateExternalComment}
            render={({ isSubmitting, submitForm }) => (
              <>
                <div className="new-comment">
                  <Avatar image="" />
                  <Input name="externalComment" inputType="textarea" />
                </div>
                <Button
                  htmlType="submit"
                  type="primary"
                  onClick={submitForm}
                  disabled={isSubmitting}
                  className="action-button"
                >
                  Add Comment
                </Button>
              </>
            )}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Comments;
