import React, { useState } from 'react';
import { Tabs } from 'antd';
import { Header } from '~/shared/elements';
import SideMenu from '~/shared/page-coms/SideMenu';
import { PaymentMethods, BillingStatuses, Invoices, PastDuePayments } from './com';
import './Billing.scss';

const { TabPane } = Tabs;

const routes = [
  {
    key: 'methods',
    title: 'Payment Methods'
  },
  {
    key: 'payment-due',
    title: 'Due Payments'
  },
  {
    key: 'invoices',
    title: 'Invoices'
  }
  // {
  //   key: 'vendorLicenses',
  //   title: 'Vendor Licenses'
  // }
];

const Billing = ({ client }) => {
  const { stripeInfo, companyName } = client;

  const {
    activePaymentMethods,
    invoices,
    unpaidPastDuePayments,
    billingStatus,
    licenseQuantity
  } = stripeInfo || {};

  const [subscriptionStatus, setSubscriptionStatus] = useState(billingStatus);
  const [active, setActive] = useState('methods');
  const [paymentMethods, setPaymentMethods] = useState(activePaymentMethods);

  return (
    <div className="billing">
      <Tabs defaultActiveKey="methods">
        <TabPane
          tab="General"
          key="methods"
          activeKey={active}
          onTabClick={key => setActive(key)}
        >
          <div className="billing-payments">
            <BillingStatuses
              paymentMethods={paymentMethods}
              licenseQuantity={licenseQuantity}
              billingStatus={subscriptionStatus}
              setSubscriptionStatus={setSubscriptionStatus}
              setActive={setActive}
            />
            <PaymentMethods
              companyName={companyName}
              paymentMethods={paymentMethods}
              setPaymentMethods={setPaymentMethods}
            />
          </div>
        </TabPane>
        <TabPane tab="Invoices" key="invoices">
          <Invoices invoices={invoices} />
        </TabPane>
        <TabPane tab="Past Due Payments" key="payment-due">
          <PastDuePayments
            pastDuePayments={unpaidPastDuePayments}
            paymentMethods={paymentMethods}
            setPaymentMethods={setPaymentMethods}
            setSubscriptionStatus={setSubscriptionStatus}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Billing;
