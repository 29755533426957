import { object, string } from 'yup';

export const pastDuePaymentFormValidation = paymentMethods =>
  object().shape({
    paymentMethodId: string()
      .required('Payment method is required.')
      .test('valid', 'Invalid payment method', selection =>
        paymentMethods.find(({ id }) => id === selection)
      )
      .nullable()
  });

export const pastDuePaymentFormDefaults = (duePayment, paymentMethods) => {
  const defaultMethod = paymentMethods.find(({ scheduledPayment }) => scheduledPayment);
  return {
    paymentMethodId: defaultMethod ? defaultMethod.id : null,
    pastDuePaymentId: duePayment.id
  };
};
