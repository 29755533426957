import React from 'react';
import { Formik, Form } from 'formik';
import { Button } from 'antd';
import { Input } from '~/shared/forms';
import alert from '~/shared/elements/Notification';
import {
  PasswordResetValidation,
  PasswordResetDefaults,
  resetValidationSchema,
  resetDefaults
} from './PasswordReset.validation';

const PasswordReset = ({ setSignInOption, requestPasswordReset, resetPassword }) => {
  const token = new URL(window.location.href).searchParams.get('reset_password_token');
  const submitRequestPasswordReset = async (values, actions) => {
    const { email } = values;
    const { setSubmitting } = actions;

    try {
      await requestPasswordReset({
        variables: { email }
      });
      alert(
        'Password Reset Success',
        'A password reset email has been sent.',
        4,
        'success'
      );
      setSignInOption('login');
    } catch (e) {
      console.log(e);
    }

    setSubmitting(false);
  };

  const resetPasswordClicked = async ({ newPassword, confirmPassword }, actions) => {
    const { setSubmitting } = actions;

    try {
      await resetPassword({
        variables: {
          resetPasswordAttributes: { password: newPassword, confirmPassword, token }
        }
      });
      alert('Password Reset Success', 'Your password has been reset.', 4, 'success');
      setSignInOption('login');
    } catch (e) {
      console.log(e);
    }

    setSubmitting(false);
  };
  return (
    <>
      {token ? (
        <Formik
          validationSchema={resetValidationSchema}
          initialValues={resetDefaults}
          onSubmit={resetPasswordClicked}
          render={({ isSubmitting }) => (
            <Form>
              <h2>Set New Password</h2>

              <Input
                name="newPassword"
                inputType="password"
                autoComplete="password"
                placeholder="New Password"
              />

              <Input
                name="confirmPassword"
                inputType="password"
                autoComplete="password"
                placeholder="Confirm New Password"
              />

              <div className="button-group">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitting}
                  className="submit-button"
                >
                  Save Password
                </Button>
              </div>
            </Form>
          )}
        />
      ) : (
        <Formik
          validationSchema={PasswordResetValidation()}
          initialValues={PasswordResetDefaults()}
          validateOnBlur={false}
          onSubmit={submitRequestPasswordReset}
          render={({ errors, isSubmitting }) => (
            <Form>
              <h2>Password Reset</h2>
              <p>Enter your email address</p>
              <Input
                name="email"
                inputType="input"
                autoComplete="email"
                placeholder="Email address..."
                data-test="email"
              />

              {errors.api && <div>{errors.api}</div>}

              <div className="button-group">
                <Button type="link" onClick={() => setSignInOption('login')}>
                  Login
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitting}
                  data-test="button-submit"
                >
                  Send
                </Button>
              </div>
            </Form>
          )}
        />
      )}
    </>
  );
};

export default PasswordReset;
