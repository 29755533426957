import React, { useState } from 'react';
import { Table } from 'antd';
import { Pie, MiniBar } from 'ant-design-pro/lib/Charts';
import { Wrapper } from '~/shared/elements';
import timeDiff from '~/lib/utils/dateUtils';
import { stringCompare } from '~/lib/utils/stringUtils';
import TicketDetail from './com/TicketDetail';
import './Tickets.scss';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const ticketColumns = [
  {
    title: 'Ticket ID',
    dataIndex: 'ticketNumber'
  },
  {
    title: 'Category',
    dataIndex: 'category'
  },
  {
    title: 'User Account',
    dataIndex: ['clientUser', 'email']
  },
  {
    title: 'Contact',
    dataIndex: ['clientUser', 'name']
  },
  {
    title: 'Phone No.',
    dataIndex: ['clientUser', 'phone']
  },
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: (a, b) => stringCompare(b.status, a.status),
    render: status => (
      <span
        className={status
          .split(' ')
          .join('-')
          .toLowerCase()}
      >
        {status}
      </span>
    )
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    sorter: (a, b) => stringCompare(b.priority, a.priority),
    render: priority => <span className={priority.toLowerCase()}>{priority}</span>
  },
  {
    title: 'Duration',
    dataIndex: 'createdAtFull',
    key: 'createdAtFull',
    render: (createdAtFull, { durationEnd }) =>
      timeDiff(
        new Date(parseInt(durationEnd, 10)),
        new Date(parseInt(createdAtFull, 10))
      ),
    sorter: (a, b) => stringCompare(b.createdAt, a.createdAt)
  }
];

const Tickets = ({ tickets }) => {
  const [selectedTicket, setSelectedTicket] = useState(null);
  const newTickets = tickets.filter(({ status }) => status === 'New');
  const inProgressTickets = tickets.filter(({ status }) => status === 'In Progress');
  const completedTickets = tickets.filter(({ status }) => status === 'Complete');

  const monthSortedTicketsObj = tickets.reduce(
    (a, { createdAt, ...rest }) => ({
      ...a,
      [months[new Date(createdAt).getMonth()]]: [
        ...(a[months[new Date(createdAt).getMonth()]] || []),
        { createdAt, ...rest }
      ]
    }),
    {}
  );

  const monthTotals = months.map(month => ({
    x: monthSortedTicketsObj[month] ? month : `${month}(N/A)`,
    y: monthSortedTicketsObj[month]
      ? parseFloat(
          (
            monthSortedTicketsObj[month].reduce(
              (a, { createdAtFull, firstExternalCommentDate }) =>
                a + parseInt(firstExternalCommentDate) - parseInt(createdAtFull),
              0
            ) /
            monthSortedTicketsObj[month].length /
            1000 /
            60
          ).toFixed(2)
        )
      : 0.1
  }));

  const monthsWithTotals = monthTotals.filter(({ y }) => y !== 0.1 && !!y);

  const ticketAverageMinutes = monthsWithTotals.reduce((a, { y }) => a + y, 0);
  const nationalAverageMinutes = 12 * 60 + 10;

  const percentFaster = Math.round(
    (nationalAverageMinutes / ticketAverageMinutes - 1) * 100
  );

  return (
    <Wrapper className="page tickets">
      <div className="flex">
        <div className="floating-section">
          <h3>Ticket Overview</h3>
          <Pie
            hasLegend
            // title="title"
            subTitle="Total Tickets"
            total={() => tickets.length}
            data={[
              { x: 'New', y: newTickets.length },
              { x: 'In Progress', y: inProgressTickets.length },
              { x: 'Completed', y: completedTickets.length }
            ]}
            height={294}
          />
        </div>
        <div className="floating-section">
          <h3>Ticket Response Time</h3>
          <p>Our average for your company in minutes</p>
          <MiniBar
            // height={46}
            data={monthTotals}
          />
          <p>
            We are <span style={{ color: '#52C41A' }}>{percentFaster}% faster</span> than
            the{' '}
            <a
              href="https://www.superoffice.com/blog/response-times/"
              target="_blank"
              style={{ color: '#33ABFB' }}
            >
              national average
            </a>
          </p>
        </div>
      </div>
      <div className="floating-section">
        <h3>Tickets</h3>
        <Table
          className="ticket-table"
          dataSource={tickets}
          columns={ticketColumns}
          rowKey={({ id }) => id}
          rowClassName="row"
          bordered
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText: 'There are no Tickets found.'
          }}
          onRow={({ id }) => ({
            onClick: () => setSelectedTicket(id)
          })}
        />
      </div>
      <TicketDetail
        selectedTicket={selectedTicket}
        setSelectedTicket={setSelectedTicket}
      />
    </Wrapper>
  );
};

export default Tickets;
