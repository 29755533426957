import React from 'react';
import { Wrapper } from '~/shared/elements';
import { AppRiver, KnowBefore, Nessus, PasswordSecurity, Tickets } from './com';
import DNSFilter from '~/shared/page-coms/DNSFilter';
import './Dashboard.scss';

const Dashboard = () => {
  return (
    <Wrapper className="page dashboard">
      <div className="float-wrapper">
        <DNSFilter />
        <AppRiver />
      </div>
      <div className="float-wrapper">
        <Nessus />
        <KnowBefore />
        <PasswordSecurity />
        <Tickets />
      </div>
    </Wrapper>
  );
};

export default Dashboard;
