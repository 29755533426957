import React from 'react';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Spinner } from '~/shared/elements';
import ApiError from '~/shared/errors/ApiError';
import Nessus from './Nessus';

const NESSUS_SCANS_AND_NOTES = gql`
  {
    nessusScans {
      id
      name
    }
    nessusNotes {
      id
      scanId
      scanName
      vulnerabilityId
      vulnerabilityName
      note
    }
  }
`;

const NESSUS_SCAN_DETAILS = gql`
  query($id: String!) {
    nessusScanDetails(id: $id) {
      id
      count
      severity
      name
      category
    }
  }
`;

const NessusData = props => {
  const { data, error, loading } = useQuery(NESSUS_SCANS_AND_NOTES);
  const [
    loadNessusScanDetails,
    { loading: nessusScanDetailsLoading, data: nessusScanDetailsData }
  ] = useLazyQuery(NESSUS_SCAN_DETAILS, { fetchPolicy: 'network-only' });

  if (loading) return <Spinner />;
  if (error) return <ApiError error={error} />;

  const { nessusScanDetails = [] } = nessusScanDetailsData || {};
  const { nessusNotes = [], nessusScans = [] } = data;

  return (
    <Nessus
      nessusScans={nessusScans || []}
      nessusNotes={nessusNotes}
      loadNessusScanDetails={loadNessusScanDetails}
      nessusScanDetailsLoading={nessusScanDetailsLoading}
      nessusScanDetails={nessusScanDetails}
      {...props}
    />
  );
};

export default NessusData;
