import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import PastDuePaymentForm from './PastDuePaymentForm';

const MANUALLY_PAY_PAST_DUE_PAYMENT = gql`
  mutation($delinquentPaymentAttributes: DelinquentPaymentAttributes!) {
    manuallyPayPastDuePayment(delinquentPaymentAttributes: $delinquentPaymentAttributes) {
      id
      billingStatus
      licenseQuantity
      invoices {
        id
        amountDue
        amountPaid
        invoiceNumber
        status
        link
      }
      unpaidPastDuePayments {
        id
        unpaidAmount
        createdAt
      }
      activePaymentMethods {
        id
        status
        lastFour
        name
        paymentType
        scheduledPayment
      }
    }
  }
`;

const PastDuePaymentFormData = props => {
  const [manuallyPayPastDuePayment] = useMutation(MANUALLY_PAY_PAST_DUE_PAYMENT);
  return (
    <PastDuePaymentForm
      {...props}
      manuallyPayPastDuePayment={manuallyPayPastDuePayment}
    />
  );
};

export default PastDuePaymentFormData;
