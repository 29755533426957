import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Spinner } from '~/shared/elements';
import ApiError from '~/shared/errors/ApiError';
import Billing from './Billing';

const CLIENT = gql`
  {
    selfClient {
      id
      stripeInfo {
        id
        billingStatus
        licenseQuantity
        invoices {
          id
          amountDue
          amountPaid
          invoiceNumber
          status
          link
          paidOn
        }
        unpaidPastDuePayments {
          id
          unpaidAmount
          createdAt
        }
        activePaymentMethods {
          id
          status
          lastFour
          name
          paymentType
          scheduledPayment
        }
      }
    }
  }
`;

const BillingData = props => {
  const { data, error, loading } = useQuery(CLIENT);

  if (loading) return <Spinner />;
  if (error) return <ApiError error={error} />;

  return <Billing {...props} client={data.selfClient} />;
};

export default BillingData;
