import React from 'react';
import { Field } from 'formik';
import { Form } from 'antd';
import './ColorPicker.scss';
import ColorPicker from 'rc-color-picker';

const { Item } = Form;

const AntColorPicker = ({ field, form, options, children, ...rest }) => {
  const errorExistsAndFieldTouched =
    !!form.errors[field.name] && !!form.touched[field.name];

  return (
    <Item
      label={rest.label}
      validateStatus={errorExistsAndFieldTouched ? 'warning' : null}
      help={errorExistsAndFieldTouched ? form.errors[field.name] : null}
      className="color-picker-form-item"
    >
      <ColorPicker
        {...field}
        onChange={({ color }) => form.setFieldValue(field.name, color)}
        {...rest}
        animation="slide-up"
        color={form.values[field.name]}
        defaultColor={form.values[field.name]}
      />
    </Item>
  );
};

const FormikColorPicker = props => <Field component={AntColorPicker} {...props} />;

export default FormikColorPicker;
