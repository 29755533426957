import React from 'react';
import { Alert, Popconfirm, Button } from 'antd';
import alert from '~/shared/elements/Notification';
import { WarningOutlined } from '@ant-design/icons';
import './BillingStatuses.scss';

const BillingStatuses = ({
  startBilling,
  billingStatus,
  licenseQuantity,
  setActive,
  setSubscriptionStatus,
  paymentMethods
}) => {
  const handleStartBilling = async () => {
    try {
      const {
        data: { startBilling: updatedSubscription }
      } = await startBilling();
      setSubscriptionStatus(updatedSubscription.billingStatus);
      alert('Success', 'Subscription confirmed', 4, 'success', 'topRight');
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  const statusMessage = {
    inactive: {
      message: (
        <>
          <p className="inactive-status-text">Billing is not set up:</p>
          <Popconfirm
            placement="topRight"
            title="Start billing cycle?"
            okText="Confirm"
            cancelText="No"
            icon={<WarningOutlined style={{ color: 'red' }} />}
            onConfirm={handleStartBilling}
          >
            <Button className="billing-btn" type="primary">
              START BILLING
            </Button>
          </Popconfirm>
        </>
      )
    },
    cancelled: {
      message: (
        <p className="inactive-status-text">
          Your subscription has been cancelled due to failure to pay:
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={() => setActive('payment-due')}>view</a>
        </p>
      )
    },
    past_due: {
      message: (
        <p className="inactive-status-text">
          Your subscription is past due:
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a onClick={() => setActive('payment-due')}>view</a>
        </p>
      )
    },
    missing_payment: {
      message: (
        <p className="inactive-status-text">
          There are no payment methods attached to your account.
        </p>
      )
    },
    billing_error: {
      message: (
        <p className="inactive-status-text">
          There was an error processing your payment. Contact a Birch Admin.
        </p>
      )
    }
  };

  return (
    <div className="billing-status-container">
      {!paymentMethods.length && (
        <Alert
          className="active-billing-alert"
          message={statusMessage.missing_payment.message}
          type="error"
        />
      )}
      {!!paymentMethods.length && billingStatus === 'active' && (
        <Alert
          className="active-billing-alert"
          message={
            <p className="active-status-text">
              Billing is active for {licenseQuantity} licenses.
            </p>
          }
          type="success"
        />
      )}
      {!!paymentMethods.length && billingStatus !== 'active' && (
        <Alert
          className="active-billing-alert"
          message={statusMessage[billingStatus].message}
          type="error"
        />
      )}
    </div>
  );
};

export default BillingStatuses;
