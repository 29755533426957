import React, { useState } from 'react';
import { Table, Popconfirm, Button, Modal } from 'antd';
import { usePlaidLink } from 'react-plaid-link';
import CreateCreditCardPayment from './com/CreateCreditCardPayment';
import alert from '~/shared/elements/Notification';
import {
  PlusCircleOutlined,
  BankOutlined,
  WarningOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import './PaymentMethods.scss';
import { Spinner } from '~/shared/elements';

const PaymentMethods = ({
  paymentMethods,
  setPaymentMethods,
  removePaymentMethod,
  setDefaultPayment,
  addBankAccountPaymentMethod,
  companyName,
  data
}) => {
  const { plaidLinkToken, plaidEnv } = data;

  const [addingPayment, setAddingPayment] = useState(false);
  const [paymentToDelete, setPaymentToDelete] = useState({});
  const [paymentToActivate, setpaymentToActivate] = useState({});
  const [addingAch, setAddingAch] = useState(false);

  // Handles the creation of a new payment
  const handleNewPayment = newPayment => {
    setPaymentMethods(existingMethods => [...existingMethods, newPayment]);
    setAddingPayment(false);
  };
  const submitAddBankAccountPaymentMethod = async (publicToken, metadata) => {
    const { account, institution } = metadata;
    // Send the public_token and account ID to your app server.

    try {
      setAddingAch(true);
      const {
        data: { addBankAccountPaymentMethod: newAchMethod }
      } = await addBankAccountPaymentMethod({
        variables: {
          publicToken,
          achBankAccount: {
            id: account.id,
            name: `${institution.name}: ${account.name}`,
            mask: account.mask
          }
        }
      });

      alert('Success', 'Bank account connected.', 4, 'success', 'topRight');
      handleNewPayment(newAchMethod);
      setAddingAch(false);
    } catch (e) {
      // alert('Error', e.message, 4, 'error', 'topRight');
    }
  };
  const linkHandler = usePlaidLink({
    token: plaidLinkToken,
    product: ['auth'],
    env: plaidEnv,
    clientName: companyName,
    selectAccount: true,
    onSuccess: submitAddBankAccountPaymentMethod,
    onExit: err => {
      // The user exited the Link flow.
      if (err != null) {
        // The user encountered a Plaid API error prior to exiting.
        alert('Error', 'Unable to select account.', 4, 'error', 'topRight');
      }
    }
  });
  const handleAddAch = () => {
    linkHandler.open();
  };

  // Handles deleting a payment
  const handleDeletePayment = async payment => {
    setPaymentToDelete(payment);

    try {
      const {
        data: { removePaymentMethod: deleted }
      } = await removePaymentMethod({
        variables: {
          paymentId: payment.id
        }
      });

      if (deleted) {
        setPaymentMethods(existingMethods =>
          existingMethods.filter(({ id }) => id !== payment.id)
        );
        alert('Success', 'Payment method deleted.', 4, 'success', 'topRight');
      } else {
        alert('Error', 'Could not delete payment method.', 4, 'error', 'topRight');
      }
    } catch (e) {
      // alert('Error', `${e.message}`, 4, 'error', 'topRight');
    }
    setPaymentToDelete({});
  };

  const handleActivate = async payment => {
    setpaymentToActivate(payment);
    try {
      const {
        data: { setDefaultPayment: updatedActivePayments }
      } = await setDefaultPayment({
        variables: { paymentId: payment.id }
      });

      setPaymentMethods(updatedActivePayments);
      alert('Success', 'Updated default method.', 4, 'success', 'topRight');
    } catch (e) {
      // alert('Error', `${e.message}`, 4, 'error', 'topRight');
    }
    setpaymentToActivate({});
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Type',
      dataIndex: 'paymentType'
    },
    {
      title: 'Last 4',
      dataIndex: 'lastFour'
    },
    {
      title: 'Default',
      dataIndex: 'scheduledPayment',
      render: scheduledPayment =>
        scheduledPayment ? (
          <CheckOutlined className="active-icon" style={{ color: 'green' }} />
        ) : (
          <CloseOutlined className="active-icon" style={{ color: 'red' }} />
        )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      onCell: () => ({ onClick: e => e.stopPropagation() }),
      render: (_, record) => (
        <div>
          <Popconfirm
            placement="topRight"
            title="Delete payment?"
            okText="Yes"
            cancelText="No"
            icon={<WarningOutlined style={{ color: 'red' }} />}
            onConfirm={() => handleDeletePayment(record)}
            disabled={record.id === paymentToDelete.id || record.scheduledPayment}
          >
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              disabled={record.id === paymentToDelete.id || record.scheduledPayment}
            >
              Delete
            </Button>
          </Popconfirm>
        </div>
      )
    },
    {
      title: 'Activate',
      dataIndex: 'activate',
      onCell: () => ({ onClick: e => e.stopPropagation() }),
      render: (_, record) => (
        <div>
          <Popconfirm
            placement="topRight"
            title="Make default payment?"
            okText="Yes"
            cancelText="No"
            icon={<WarningOutlined style={{ color: 'red' }} />}
            onConfirm={() => handleActivate(record)}
            disabled={record.scheduledPayment || record.id === paymentToActivate.id}
          >
            <Button
              type="info"
              disabled={record.scheduledPayment || record.id === paymentToActivate.id}
            >
              Activate
            </Button>
          </Popconfirm>
        </div>
      )
    }
  ];

  return (
    <div className="payment-methods">
      {addingAch ? (
        <Spinner />
      ) : (
        <>
          <div className="add-method-btns">
            <Button
              type="link"
              icon={<PlusCircleOutlined />}
              onClick={() => setAddingPayment(true)}
            >
              Add Payment Method
            </Button>
            <Button type="link" icon={<BankOutlined />} onClick={handleAddAch}>
              Add Bank Account
            </Button>
          </div>
          <Table
            dataSource={paymentMethods}
            columns={columns}
            rowKey={({ id }) => id}
            rowClassName="row"
          />
          <Modal
            title="Add new payment method"
            width="40%"
            visible={!!addingPayment}
            onCancel={() => setAddingPayment(null)}
            footer={null}
          >
            <CreateCreditCardPayment onCreate={handleNewPayment} />
          </Modal>
        </>
      )}
    </div>
  );
};

export default PaymentMethods;
