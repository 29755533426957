import React from 'react';
import { Table } from 'antd';
import { Pie } from 'ant-design-pro/lib/Charts';
import { Line } from 'react-chartjs-2';
import { Wrapper } from '~/shared/elements';
import './PasswordSecurity.scss';

const trainingCampaignColumns = [
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone'
  },
  {
    title: 'Email',
    dataIndex: 'email'
  },
  {
    title: 'Bad Login Attempts',
    dataIndex: 'badLoginAttempts'
  },
  {
    title: 'Expiration Date',
    dataIndex: 'passwordExpirationDate'
  }
];

const PasswordSecurity = ({ clientUsers = [] }) => {
  console.log(clientUsers);
  const fifteenDaysFromNow = new Date(Date.now() + 1000 * 60 * 60 * 24 * 15);
  const thirtyDaysFromNow = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30);

  const exemptClientUsers = clientUsers.filter(
    ({ passwordNeverExpires }) => !!passwordNeverExpires
  );
  const currentClientUsers = clientUsers.filter(
    ({ passwordExpired }) => !passwordExpired
  );
  const expiredClientUsers = clientUsers.filter(
    ({ passwordExpired }) => !!passwordExpired
  );
  const expiringInLessThanFifteenClientUsers = clientUsers.filter(
    ({ passwordExpirationDate }) =>
      !!passwordExpirationDate && new Date(passwordExpirationDate) <= fifteenDaysFromNow
  );
  const expiringInLessThanThirtyClientUsers = clientUsers.filter(
    ({ passwordExpirationDate }) =>
      !!passwordExpirationDate && new Date(passwordExpirationDate) <= thirtyDaysFromNow
  );

  const exemptPercent = Math.round((exemptClientUsers.length / clientUsers.length) * 100);
  const currentPercent = Math.round(
    (currentClientUsers.length / clientUsers.length) * 100
  );
  const expiredPercent = Math.round(
    (expiredClientUsers.length / clientUsers.length) * 100
  );
  const fifteenPercent = Math.round(
    (expiringInLessThanFifteenClientUsers.length / clientUsers.length) * 100
  );
  const thirtyPercent = Math.round(
    (expiringInLessThanThirtyClientUsers.length / clientUsers.length) * 100
  );

  return (
    <Wrapper className="page password-security">
      <div className="floating-section">
        <h3>Password Overview</h3>
        <Pie
          percent={exemptPercent}
          subTitle="Exempted"
          total={`${exemptPercent}%`}
          height={140}
          color="#F5222D"
        />
        <Pie
          percent={expiredPercent}
          subTitle="Expired"
          total={`${expiredPercent}%`}
          height={140}
          color="#fbb709"
        />
        <Pie
          percent={fifteenPercent}
          subTitle="15 Days"
          total={`${fifteenPercent}%`}
          height={140}
          color="#f2f313"
        />
        <Pie
          percent={thirtyPercent}
          subTitle="30 Days"
          total={`${thirtyPercent}%`}
          height={140}
          color="#2FC25B"
        />
        <Pie
          percent={currentPercent}
          subTitle="Current"
          total={`${currentPercent}%`}
          height={140}
          color="#1890FF"
        />
      </div>

      <div className="floating-section training-campaigns">
        <h3>Exempted</h3>
        <Table
          dataSource={exemptClientUsers}
          columns={trainingCampaignColumns}
          rowKey={({ id }) => id}
          rowClassName="row"
          bordered
          size="small"
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText: 'There are no Users found.'
          }}
        />
      </div>

      <div className="floating-section training-campaigns">
        <h3>Expired</h3>
        <Table
          dataSource={expiredClientUsers}
          columns={trainingCampaignColumns}
          rowKey={({ id }) => id}
          rowClassName="row"
          bordered
          size="small"
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText: 'There are no Users found.'
          }}
        />
      </div>

      <div className="floating-section training-campaigns">
        <h3>Password Expiring In 15 Days</h3>
        <Table
          dataSource={expiringInLessThanFifteenClientUsers}
          columns={trainingCampaignColumns}
          rowKey={({ id }) => id}
          rowClassName="row"
          bordered
          size="small"
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText: 'There are no Users found.'
          }}
        />
      </div>

      <div className="floating-section training-campaigns">
        <h3>Password Expiring In 30 Days</h3>
        <Table
          dataSource={expiringInLessThanThirtyClientUsers}
          columns={trainingCampaignColumns}
          rowKey={({ id }) => id}
          rowClassName="row"
          bordered
          size="small"
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText: 'There are no Users found.'
          }}
        />
      </div>

      <div className="floating-section training-campaigns">
        <h3>Current</h3>
        <Table
          dataSource={currentClientUsers}
          columns={trainingCampaignColumns}
          rowKey={({ id }) => id}
          rowClassName="row"
          bordered
          size="small"
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText: 'There are no Users found.'
          }}
        />
      </div>
    </Wrapper>
  );
};

export default PasswordSecurity;
