import React from 'react';
import { Field } from 'formik';
import { Select, Form } from 'antd';

const { Item } = Form;
const { Option } = Select;

const AntSelect = ({ field, form, options, children, ...rest }) => {
  const errorExistsAndFieldTouched =
    !!form.errors[field.name] && !!form.touched[field.name];
  return (
    <Item
      label={rest.label}
      validateStatus={errorExistsAndFieldTouched ? 'warning' : null}
      help={errorExistsAndFieldTouched ? form.errors[field.name] : null}
    >
      <Select
        {...field}
        onChange={selection => form.setFieldValue(field.name, selection)}
        {...rest}
        defaultValue={form.values[field.name]}
      >
        {!options
          ? children
          : Object.keys(options).map(key => {
              return (
                <Option key={key} value={key}>
                  {options[key]}
                </Option>
              );
            })}
      </Select>
    </Item>
  );
};

const FormikSelect = props => <Field component={AntSelect} {...props} />;

export default FormikSelect;
