import React, { useState } from 'react';
import { Button } from 'antd';
import { MiniArea } from 'ant-design-pro/lib/Charts';
import './Tickets.scss';

const Tickets = ({ tickets }) => {
  const [timePeriod, setTimePeriod] = useState('week');
  const sevenDaysAgo = new Date(Date.now() - 1000 * 60 * 60 * 24 * 7);
  sevenDaysAgo.setHours(0, 0, 0, 0);

  const thirtyDaysAgo = new Date(Date.now() - 1000 * 60 * 60 * 24 * 30);
  thirtyDaysAgo.setHours(0, 0, 0, 0);

  const dateSortedTickets = tickets.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  const weekTickets = dateSortedTickets.filter(
    ({ createdAt }) => !!createdAt && new Date(createdAt) >= sevenDaysAgo
  );
  const monthTickets = dateSortedTickets.filter(
    ({ createdAt }) => !!createdAt && new Date(createdAt) >= thirtyDaysAgo
  );

  const weekGraph = timePeriod === 'week';

  const weekDates = [...new Set(weekTickets.map(({ createdAt }) => createdAt))];
  const monthDates = [...new Set(monthTickets.map(({ createdAt }) => createdAt))];

  const weekData = weekDates.map(date => ({
    x: date,
    y: weekTickets.reduce((a, { createdAt }) => (date === createdAt ? a + 1 : a), 0)
  }));

  const monthData = monthDates.map(date => ({
    x: date,
    y: monthTickets.reduce((a, { createdAt }) => (date === createdAt ? a + 1 : a), 0)
  }));

  return (
    <div className="floating-section tickets">
      <div
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <h3>Tickets</h3>
        <div className="counts">
          <Button
            type="primary"
            onClick={() => setTimePeriod('week')}
            disabled={weekGraph}
          >
            Week
          </Button>
          <Button
            type="primary"
            onClick={() => setTimePeriod('month')}
            disabled={!weekGraph}
          >
            Month
          </Button>
        </div>
      </div>
      <h4 style={{ color: 'rgba(0,0,0,.85)', fontSize: '30px', lineHeight: '38px' }}>
        {weekGraph ? weekTickets.length : monthTickets.length}
      </h4>

      {weekGraph ? (
        <MiniArea line height={90} data={weekData} />
      ) : (
        <MiniArea line height={90} data={monthData} />
      )}

      <div style={{ backgroundColor: '#eff2f5', height: '1px', width: '100%' }} />
      <div style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
        {weekGraph
          ? sevenDaysAgo.toLocaleDateString()
          : thirtyDaysAgo.toLocaleDateString()}{' '}
        - {new Date().toLocaleDateString()}
      </div>
    </div>
  );
};

export default Tickets;
