import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import './Avatar.scss';

const Avatar = ({ image }) =>
  image ? (
    <img className="user-avatar img" src={image} alt="avatar" />
  ) : (
    <UserOutlined className="user-avatar" />
  );

export default Avatar;
