const download = (filename, text) => {
  // thanks https://stackoverflow.com/questions/3665115/how-to-create-a-file-in-memory-for-user-to-download-but-not-through-server
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`
  );
  element.setAttribute('download', filename.replace(/[/\\?%*:|"<>'\s]/g, '_')); // get rid of non allowed characters.  here's a cute regex - https://stackoverflow.com/questions/42210199/remove-illegal-characters-from-a-file-name-but-leave-spaces
  element.click();
};

export default download;
