import React from 'react';
import { Input, Icon, Button } from 'antd';

export default function(
  dataIndex,
  searchInputEl,
  filter,
  { searchPlaceholder = `Search ${dataIndex}` } = {}
) {
  const fallbackSearch = (value, record) =>
    dataIndex.split('.').reduce((o, i) => !!o && o[i], record)
      ? dataIndex
          .split('.')
          .reduce((o, i) => o[i], record)
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : false;
  // For Data Index values that are nested
  const customFilter = filter || fallbackSearch;

  return {
    // eslint-disable-next-line
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputEl}
          placeholder={searchPlaceholder}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={confirm}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: customFilter,
    onFilterDropdownVisibleChange: visible =>
      !!visible && setTimeout(() => searchInputEl.current.select()),
    render: text => <span>{text}</span>
  };
}
