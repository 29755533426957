import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import PasswordReset from './PasswordReset';

const REQUEST_PASSWORD_RESET = gql`
  mutation($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

const PASSWORD_RESET = gql`
  mutation resetPassword($resetPasswordAttributes: ResetPasswordAttributes!) {
    resetPassword(resetPasswordAttributes: $resetPasswordAttributes)
  }
`;

const PasswordResetData = props => {
  const [requestPasswordReset] = useMutation(REQUEST_PASSWORD_RESET);
  const [resetPassword] = useMutation(PASSWORD_RESET);

  return (
    <PasswordReset
      requestPasswordReset={requestPasswordReset}
      resetPassword={resetPassword}
      {...props}
    />
  );
};

export default PasswordResetData;
