import React from 'react';
import { Table } from 'antd';
import { Gauge } from 'ant-design-pro/lib/Charts';
import { Line } from 'react-chartjs-2';
import { Wrapper } from '~/shared/elements';
import PhishingCampaigns from './com/PhishingCampaigns';
import './KnowBefore.scss';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const percentColors = [
  { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
  { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
  { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } }
];

const getColorForPercentage = pct => {
  const index = percentColors.findIndex(percentColor => pct <= percentColor.pct);
  const lower = percentColors[index - 1];
  const upper = percentColors[index];
  const range = upper.pct - lower.pct;
  const rangePct = (pct - lower.pct) / range;
  const pctLower = 1 - rangePct;
  const pctUpper = rangePct;
  const color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
  };
  return `rgb(${[color.r, color.g, color.b].join(',')})`;
};

const trainingCampaignColumns = [
  {
    title: 'Campaigns Name',
    dataIndex: 'name'
  },
  {
    title: 'Campaign Group',
    dataIndex: 'groups',
    render: ([{ name }]) => name
  },
  // {
  //   title: 'Previous Result',
  //   dataIndex: ['user', 'id']
  // },
  {
    title: 'Duration',
    dataIndex: 'content',
    render: ([{ duration }]) => `${duration} days`
  },
  // {
  //   title: 'Difficulty Level',
  //   dataIndex: 'difficulty_level'
  // },
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    render: start_date => new Date(start_date).toLocaleDateString()
  },
  {
    title: 'Status',
    dataIndex: 'status'
  }
];

const LINE_CONFIGURATION_DEFAULTS = {
  fill: false,
  lineTension: 0.1,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBackgroundColor: '#fff',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10
};

const KnowBefore = ({ account, phishingCampaigns, users, traniningCampaigns }) => {
  const {
    current_risk_score: currentRiskScore = 0,
    risk_score_history: riskScoreHistory = []
  } = account || {};

  const riskTimelineTotals = Object.entries(
    riskScoreHistory.reduce((a, { risk_score, date }) => {
      console.log(months[new Date(date).getMonth()]);
      const monthYear = `${months[new Date(date).getMonth()]}.${new Date(date)
        .getFullYear()
        .toString()}`;
      const { riskScoreTotal: existingRiskScoreTotal = 0, count: existingCount = 0 } =
        a[monthYear] || {};
      return {
        ...a,
        [monthYear]: {
          riskScoreTotal: existingRiskScoreTotal + risk_score,
          count: existingCount + 1
        }
      };
    }, {})
  );

  const labels = months.filter(month =>
    riskTimelineTotals.some(([monthYear]) => monthYear.includes(month))
  );

  const riskTimelineData = labels.map(month => {
    const [, { riskScoreTotal, count }] = riskTimelineTotals.find(([monthYear]) =>
      monthYear.includes(month)
    );
    return riskScoreTotal / count;
  });

  const data = {
    labels,
    datasets: [
      {
        ...LINE_CONFIGURATION_DEFAULTS,
        label: 'Risk Score',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        data: riskTimelineData
      },
      {
        ...LINE_CONFIGURATION_DEFAULTS,
        label: 'Low Risk',
        borderDash: [10, 10],
        backgroundColor: '#00d100',
        borderColor: '#00d100',
        pointBorderColor: '#00d100',
        pointHoverBackgroundColor: '#00d100',
        pointHoverBorderColor: '#00d100',
        data: labels.map(() => 20)
      },
      {
        ...LINE_CONFIGURATION_DEFAULTS,
        label: 'Medium Risk',
        borderDash: [10, 10],
        backgroundColor: '#f2fd00',
        borderColor: '#f2fd00',
        pointBorderColor: '#f2fd00',
        pointHoverBackgroundColor: '#f2fd00',
        pointHoverBorderColor: '#f2fd00',
        data: labels.map(() => 40)
      },
      {
        ...LINE_CONFIGURATION_DEFAULTS,
        label: 'High Risk',
        borderDash: [10, 10],
        backgroundColor: '#ff6900',
        borderColor: '#ff6900',
        pointBorderColor: '#ff6900',
        pointHoverBackgroundColor: '#ff6900',
        pointHoverBorderColor: '#ff6900',
        data: labels.map(() => 60)
      },
      {
        ...LINE_CONFIGURATION_DEFAULTS,
        label: 'Critical Risk',
        borderDash: [10, 10],
        backgroundColor: '#ee3733',
        borderColor: '#ee3733',
        pointBorderColor: '#ee3733',
        pointHoverBackgroundColor: '#ee3733',
        pointHoverBorderColor: '#ee3733',
        data: labels.map(() => 80)
      }
    ]
  };

  return (
    <Wrapper className="page know-before">
      <div style={{ display: 'flex' }}>
        <div className="floating-section">
          <h3>Current Risk Score</h3>
          <Gauge
            title=""
            height={300}
            percent={Math.round(currentRiskScore)}
            color={getColorForPercentage(1 - Math.round(currentRiskScore) / 100)}
          />
        </div>
        <div className="floating-section">
          <h3>Risk Score Last 6 Months</h3>
          <Line
            data={data}
            options={{
              legend: {
                labels: {
                  filter: item =>
                    !['Low', 'Medium', 'High', 'Critical'].some(riskLevel =>
                      item.text.includes(riskLevel)
                    )
                }
              }
            }}
          />
        </div>
      </div>
      <PhishingCampaigns phishingCampaigns={phishingCampaigns} users={users} />
      <div className="floating-section training-campaigns">
        <h3>Training Campaigns</h3>
        <Table
          dataSource={traniningCampaigns}
          columns={trainingCampaignColumns}
          rowKey={({ campaign_id }) => campaign_id}
          rowClassName="row"
          bordered
          size="small"
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText: 'There are no Campaign Results found.'
          }}
        />
      </div>
    </Wrapper>
  );
};

export default KnowBefore;
