import { object, string, ref } from 'yup';

export const RegisterValidation = () =>
  object().shape({
    email: string()
      .email('Invalid email')
      .required('Email is required'),
    password: string()
      .oneOf([ref('confirmPassword')], 'Passwords must match.')
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters long.'),
    confirmPassword: string()
      .oneOf([ref('password')], 'Passwords must match')
      .required('Confirm password is required')
      .min(6, 'Password must be at least 6 characters long.')
  });

export const RegisterDefaults = () => ({
  email: '',
  password: '',
  confirmPassword: ''
});
