import React from 'react';
import { Field } from 'formik';
import { Form, AutoComplete } from 'antd';

const { Item } = Form;

// This component takes an object of Key: value pairs { key0: value0, key1: value1,...}
// The keys will be the datasource for autocomplete while the value will be set to the formik field <name>
// Sets the color of warning: 'success', 'warning', 'error', 'validating'
const status = 'warning';

const AntAutoComplete = ({ field, form, data, ...rest }) => {
  const errorExistsAndFieldTouched =
    !!form.errors[field.name] && !!form.touched[field.name];

  const { value, ...fieldRest } = field;
  const dataSource = Object.keys(data);
  return (
    <Item
      label={rest.label}
      validateStatus={errorExistsAndFieldTouched ? status : null}
      help={errorExistsAndFieldTouched ? form.errors[field.name] : null}
      hasFeedback
    >
      <AutoComplete
        onChange={selection => form.setFieldValue(field.name, data[selection])}
        {...fieldRest}
        defaultValue={value}
        {...rest}
        id={field.name}
        dataSource={dataSource}
        filterOption
      />
    </Item>
  );
};

const FormikAutoComplete = props => <Field component={AntAutoComplete} {...props} />;

export default FormikAutoComplete;
