import React, { useState, useEffect } from 'react';
import { Table, Tabs, Tooltip } from 'antd';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import { DateRangeSlider, Wrapper } from '~/shared/elements';
import './AppRiver.scss';

const { TabPane } = Tabs;

const releasedEmailColumns = [
  {
    title: 'Date',
    dataIndex: 'releasedDate'
  },
  {
    title: 'Released By',
    dataIndex: 'releasedBy'
  },
  {
    title: 'Sent From',
    dataIndex: 'sentFrom'
  },
  {
    title: 'Country Sent From',
    dataIndex: 'countrySentFrom'
  },
  {
    title: 'Time',
    dataIndex: 'releasedDateTime'
  }
];

const addressRiskColumns = [
  {
    title: 'Email',
    dataIndex: 'email'
  },
  {
    title: 'Threat Count',
    dataIndex: 'count'
  }
];

const weeklyStatsColumns = [
  {
    title: 'Date',
    dataIndex: 'weekDate'
  },
  {
    title: 'Threat Emails',
    dataIndex: 'spamEmails'
  },
  {
    title: 'Virus Emails',
    dataIndex: 'virusEmails'
  },
  {
    title: 'Valid Emails',
    dataIndex: 'validEmails'
  },
  {
    title: 'Total Emails',
    dataIndex: 'totalEmails'
  }
];

const AppRiver = ({ client }) => {
  const {
    appRiverWeeklyStats: defaultAppRiverWeeklyStats,
    appRiverReleasedMessages,
    appRiverAddressRiskCounts
  } = client;
  const orderedDefaultAppRiverWeeklyStats = defaultAppRiverWeeklyStats.sort(
    (a, b) => new Date(a.weekDate) - new Date(b.weekDate)
  );
  const [appRiverWeeklyStats, setAppRiverWeeklyStats] = useState(
    orderedDefaultAppRiverWeeklyStats
  );
  const dateRangeEnd = moment(
    orderedDefaultAppRiverWeeklyStats?.[orderedDefaultAppRiverWeeklyStats.length - 1]
      ?.weekDate
  )
    .startOf('day')
    .toJSON();
  const dateRangeStart = moment(orderedDefaultAppRiverWeeklyStats?.[0]?.weekDate)
    .startOf('day')
    .toJSON();

  const getDateRangeInitialValues = () => [dateRangeStart, dateRangeEnd];
  const [dateRangeValues, setDateRangeValues] = useState(getDateRangeInitialValues());

  const data = {
    labels: appRiverWeeklyStats.map(({ weekDate }) => weekDate),
    datasets: [
      {
        label: 'Virus',
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#FB0909',
        borderColor: '#FB0909',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#FB0909',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#FB0909',
        pointHoverBorderColor: '#FB0909',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: appRiverWeeklyStats.map(({ virusEmails }) => virusEmails),
        order: 0
      },
      {
        label: 'Threat',
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#FBB709',
        borderColor: '#FBB709',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#FBB709',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#FBB709',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: appRiverWeeklyStats.map(({ spamEmails }) => spamEmails),
        order: 1
      },
      {
        label: 'Valid',
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#52C41A',
        borderColor: '#52C41A',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#52C41A',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#52C41A',
        pointHoverBorderColor: '#52C41A',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: appRiverWeeklyStats.map(({ validEmails }) => validEmails),
        order: 2
      }
    ]
  };

  const spamRiskAddresses = appRiverAddressRiskCounts.filter(
    ({ type }) => type === 'spam'
  );
  const virusRiskAddresses = appRiverAddressRiskCounts.filter(
    ({ type }) => type === 'virus'
  );

  useEffect(() => {
    setAppRiverWeeklyStats(
      defaultAppRiverWeeklyStats
        .filter(
          ({ weekDate }) =>
            new Date(dateRangeValues[0]) <= new Date(weekDate) &&
            new Date(dateRangeValues[1]) >= new Date(weekDate)
        )
        .sort((a, b) => new Date(a.weekDate) - new Date(b.weekDate))
    );
    // if (dateRangeValues[0] === dateRangeValues[1])
    //   setDateRangeValues([
    //     new Date(new Date(dateRangeValues[0]) - 1000 * 3600 * 24).toISOString(),
    //     dateRangeValues[1]
    //   ]);
  }, [dateRangeValues]);

  return (
    <Wrapper className="page app-river">
      <div style={{ display: 'flex' }}>
        <div className="floating-section">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <h3>Email Security Report</h3>
            <Tooltip title="Total scanned emails split into types for the selected time frame">
              <InfoCircleOutlined style={{ color: '#1890ff' }} />
            </Tooltip>
          </div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Graph" key="1">
              <Line data={data} />
              {defaultAppRiverWeeklyStats.length > 1 && (
                <DateRangeSlider
                  timeUnit="day"
                  from={dateRangeStart}
                  to={dateRangeEnd}
                  values={dateRangeValues}
                  onChange={setDateRangeValues}
                />
              )}
            </TabPane>
            <TabPane tab="Table" key="2">
              <Table
                dataSource={appRiverWeeklyStats}
                columns={weeklyStatsColumns}
                rowKey={({ id }) => id}
                rowClassName="row"
                size="small"
                scroll={{ x: 'max-content' }}
                locale={{
                  emptyText: 'There are no Emails found.'
                }}
              />
            </TabPane>
          </Tabs>
        </div>
        <div className="floating-section">
          <h3>High Threat Users</h3>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Threat" key="1">
              <Table
                dataSource={spamRiskAddresses}
                columns={addressRiskColumns}
                rowKey={({ id }) => id}
                rowClassName="row"
                size="small"
                scroll={{ x: 'max-content' }}
                locale={{
                  emptyText: 'There are no Threats found.'
                }}
              />
            </TabPane>
            <TabPane tab="Virus" key="2">
              <Table
                dataSource={virusRiskAddresses}
                columns={addressRiskColumns}
                rowKey={({ id }) => id}
                rowClassName="row"
                size="small"
                scroll={{ x: 'max-content' }}
                locale={{
                  emptyText: 'There are no Virus Threats found.'
                }}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>

      <div className="floating-section released-emails">
        <h3>Released Emails</h3>
        <Table
          dataSource={appRiverReleasedMessages}
          columns={releasedEmailColumns}
          rowKey={({ id }) => id}
          rowClassName="row"
          bordered
          size="small"
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText: 'There are no Released Emails found.'
          }}
        />
      </div>
    </Wrapper>
  );
};

export default AppRiver;
