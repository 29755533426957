import React from 'react';
import { useGlobal } from 'reactn';
import './TopBar.scss';

const TopBar = () => {
  const [currentClientUser] = useGlobal('currentClientUser');
  const { firstName, lastName } = currentClientUser || {};

  return (
    <div className="top-bar">
      <div className="user-name">
        {firstName} {lastName}
      </div>
    </div>
  );
};

export default TopBar;
