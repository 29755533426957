import React from 'react';
import { Gauge } from 'ant-design-pro/lib/Charts';
import './KnowBefore.scss';

const percentColors = [
  { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
  { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
  { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } }
];

const getColorForPercentage = pct => {
  const index = percentColors.findIndex(percentColor => pct <= percentColor.pct);
  const lower = percentColors[index - 1];
  const upper = percentColors[index];
  const range = upper.pct - lower.pct;
  const rangePct = (pct - lower.pct) / range;
  const pctLower = 1 - rangePct;
  const pctUpper = rangePct;
  const color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
  };
  return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
};

const KnowBefore = ({ account }) => {
  const { current_risk_score: currentRiskScore = 0 } = account || {};

  return (
    <div className="floating-section">
      <h3>Security Training Risk Score</h3>
      <Gauge
        title=""
        height={200}
        percent={Math.round(currentRiskScore)}
        color={getColorForPercentage(1 - Math.round(currentRiskScore) / 100)}
      />
    </div>
  );
};

export default KnowBefore;
