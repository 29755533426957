import React from 'react';
import { Field } from 'formik';
import { Radio, Form } from 'antd';

const { Item } = Form;

// Options is an array of objects [{radioValue: <value>, text: <radio Text>}]

// The default value comes from the Field but on the first render it needs to be a prop
const AntRadioGroup = ({
  field,
  form,
  label,
  children,
  options,
  groupName,
  hasFeedback = true,
  ...rest
}) => {
  const errorExistsAndFieldTouched =
    !!form.errors[field.name] && !!form.touched[field.name];

  const { value, ...fieldRest } = field;
  return (
    <Item
      label={label}
      validateStatus={errorExistsAndFieldTouched ? 'warning' : null}
      help={errorExistsAndFieldTouched ? form.errors[field.name] : null}
      hasFeedback={hasFeedback}
    >
      <Radio.Group
        {...fieldRest}
        onChange={e => form.setFieldValue(field.name, e.target.value)}
        defaultValue={form.values[field.name]}
        {...rest}
      >
        {!options
          ? children
          : options.map(({ radioValue, text }) => {
              return (
                <Radio type="radio" name={groupName} value={radioValue} key={radioValue}>
                  {text}
                </Radio>
              );
            })}
      </Radio.Group>
    </Item>
  );
};

// Formik radio input wrapper.
const FormikRadioGroup = props => (
  <Field type="radio" component={AntRadioGroup} {...props} />
);

export default FormikRadioGroup;
