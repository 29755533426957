import React, { useState } from 'react';
import { Table, Button, Modal } from 'antd';
import money from '~/lib/utils/moneyUtils';
import PastDuePaymentForm from './com/PastDuePaymentForm';
import './PastDuePayments.scss';

const PastDuePayments = ({
  pastDuePayments,
  paymentMethods,
  setPaymentMethods,
  setSubscriptionStatus
}) => {
  const [delinquentPayments, setDelinquentPayments] = useState(pastDuePayments);
  const [pastDuePayment, setPastDuePayment] = useState(null);

  const handleOnPay = updatedSubscription => {
    setDelinquentPayments(updatedSubscription.unpaidPastDuePayments);
    setPaymentMethods(updatedSubscription.activePaymentMethods);
    setSubscriptionStatus(updatedSubscription.billingStatus);
    setPastDuePayment(null);
  };

  const columns = [
    {
      title: 'Due Date',
      dataIndex: 'createdAt'
    },
    {
      title: 'Amount Due',
      dataIndex: 'unpaidAmount',
      render: amt => money(amt * 100)
    },

    {
      title: 'Action',
      dataIndex: 'action',
      onCell: () => ({ onClick: e => e.stopPropagation() }),
      render: (_, record) => (
        <Button type="primary" onClick={() => setPastDuePayment(record)}>
          Pay
        </Button>
      )
    }
  ];
  return (
    <div className="past-due-payment">
      <Table
        dataSource={delinquentPayments}
        columns={columns}
        rowKey={({ id }) => id}
        rowClassName="row"
      />
      <Modal
        title="Pay past due payment"
        width="40%"
        visible={!!pastDuePayment}
        onCancel={() => setPastDuePayment(null)}
        footer={null}
      >
        {pastDuePayment && (
          <PastDuePaymentForm
            duePayment={pastDuePayment}
            paymentMethods={paymentMethods}
            onPay={handleOnPay}
          />
        )}
      </Modal>
    </div>
  );
};

export default PastDuePayments;
