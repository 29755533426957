import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import './Nessus.scss';

const { Option } = Select;

const SEVERITY_MAP = {
  0: 'Info',
  1: 'Low',
  2: 'Medium',
  3: 'High',
  4: 'Critical'
};

const Nessus = ({
  nessusScans,
  loadNessusScanDetails,
  nessusScanDetailsLoading,
  nessusScanDetails
}) => {
  const [firstNessusScan] = nessusScans;
  const [selectedNessusScan, setSelectedNessusScan] = useState(firstNessusScan);

  useEffect(() => {
    const { id } = selectedNessusScan || {};
    if (id) loadNessusScanDetails({ variables: { id } });
  }, [selectedNessusScan]);

  return (
    <div className="floating-section">
      <h3>Network Vulnerability</h3>
      <label>
        Location:
        <br />
        <Select
          value={selectedNessusScan?.id}
          onChange={value =>
            setSelectedNessusScan(nessusScans.find(({ id }) => id === value))
          }
          style={{ marginBottom: 16 }}
        >
          {nessusScans.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </label>
      <div className="severity-cards">
        {Object.entries(SEVERITY_MAP)
          .reverse()
          .map(([threatInt, threatString]) => (
            <div
              className={`severity-card ${threatString.toLowerCase()}`}
              key={threatInt}
            >
              <span className="severity">{threatString}</span>
              <span className="threat-count">
                {nessusScanDetails
                  .filter(({ severity }) => severity === parseInt(threatInt))
                  .reduce((a, { count }) => a + count, 0)}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Nessus;
