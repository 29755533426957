import { object, string } from 'yup';
import { isAKeyOfObj, emailRegExp, zipCodeRegExp } from '~/lib/utils/validationUtils';
import { states } from '~/lib/static/selectorFields';

export const createCreditCardPaymentValidation = () =>
  object().shape({
    paymentName: string().required('Payment name is required.'),
    name: string().required('Name is required.'),
    email: string()
      .matches(emailRegExp, 'Email is not valid')
      .required('Email is required.'),
    line1: string()
      .required('Address is required')
      .nullable(),
    city: string()
      .required('City is required')
      .nullable(),
    state: string()
      .test('valid state', 'Invalid state', state => isAKeyOfObj(state, states))
      .required('State is required')
      .nullable(),
    zipCode: string()
      .matches(zipCodeRegExp, 'Zip code is not valid.')
      .required('Zip code is required')
  });

export const createCreditCardPaymentDefaults = () => {
  return {
    paymentName: '',
    name: '',
    email: '',
    line1: '',
    city: '',
    state: null,
    zipCode: ''
  };
};
