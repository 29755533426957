import React from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useGlobal } from 'reactn';
import { Button } from 'antd';
import { Input } from '~/shared/forms';
import alert from '~/shared/elements/Notification';
import { signInValidation, signInDefaults } from './Login.validation';

const Login = ({ setSignInOption }) => {
  const [serverUrl] = useGlobal('serverUrl');
  const dispatch = useDispatch();

  const submitSignIn = async (data, actions) => {
    const { setSubmitting } = actions;
    try {
      await fetch(`${serverUrl}/api/auth/local`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(async res => {
        if (res.status !== 200) throw new Error(await res.text());
        else return res.text();
      });
      dispatch.setLoggedIn(true);
    } catch (e) {
      alert('Error', e.message, 4, 'warning');
      console.log(e);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={signInDefaults}
      validationSchema={signInValidation}
      onSubmit={submitSignIn}
      render={() => {
        return (
          <Form className="login-form">
            <p className="welcome-text">
              Welcome to the <span>Client Center</span>
            </p>

            <Input className="login-field" name="email" label="Email Address" />
            <Input
              className="login-field"
              name="password"
              inputType="password"
              label="Password"
            />
            <div className="button-group">
              <div>
                <Button type="link" onClick={() => setSignInOption('password-reset')}>
                  Reset password?
                </Button>
                <br />
              </div>
              <Button htmlType="submit" type="primary">
                Sign In
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default Login;
