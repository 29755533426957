import moment from 'moment';

export const dateTimeToString = (dateTime, format) => dateTime.format(format);
export const stringToDateTime = (string, format) => moment(string, format);

export const validateDateTime = (dateTimeObject, format) => {
  // Fail validation for null moments
  if (!dateTimeObject) return false;

  // Converts the Date/Time Picker moment to moment object using format
  const formattedDateTimeObject = stringToDateTime(
    dateTimeToString(dateTimeObject, format),
    format
  );

  // Validates new moment object
  return (
    formattedDateTimeObject &&
    formattedDateTimeObject._isAMomentObject &&
    formattedDateTimeObject._isValid &&
    formattedDateTimeObject._f === format
  );
};

/* ----------- DATE --------- */
export const datePickerFormat = 'YYYY-MM-DD';
export const todaysDateFormatted = () => {
  const date = new Date();
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  return moment(`${year}-${monthIndex + 1}-${day}`);
};

/* ----------- TIME --------- */
export const timePickerFormat = 'hh:mm A';
export const dbTimeFormat = 'HH:mm';

export const nowTimeFormatted = () =>
  moment(moment().format(timePickerFormat), timePickerFormat);
