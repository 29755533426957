import React from 'react';
import { Wrapper } from '~/shared/elements';
import './Terms.scss';

const Terms = () => {
  return (
    <Wrapper className="page terms">
      <h1>CINGO CLIENT CENTER TERMS OF USE</h1>
      <p>
        These terms of use are entered into by and between any individual or a legal
        entity, as applicable, that uses the Client Center (“You” or “Your”) and Cingo
        Solutions, LLC (“Company”, “we”, or “us”). The following terms andconditions,
        together with any documents they expressly incorporate by reference (“Terms of
        Use”), govern Youraccess to and use of the Cingo Solutions Client Center at
        cingo.center, including any content, functionality, and Client Centers offered on
        or through cingo.center (the “Client Center”).
      </p>
      <p>
        Please read the Terms of Use carefully before You use the Client Center.{' '}
        <b>
          By using the Client Center, You acceptand agree to be bound and abide by these
          Terms of Use and our Privacy Policy, found atcingo.center/privacy, incorporated
          herein by reference.
        </b>{' '}
        If You are an account administrator, the Terms ofService also apply to your use of
        the Client Center. All information we collect in the Client Center is subject to
        our Privacy Policy. By using the Client Center, You consent to all actions taken
        by us with respect to Your informationin compliance with the Privacy Policy. If
        You do not agree to these Terms of Use or the Privacy Policy, You must not access
        or use the Client Center.
      </p>
      <h3 className="section-title">Accessing the Website and Account Security</h3>
      <p>
        We reserve the right to withdraw or amend the Client Center, and any service or
        material we provide on the ClientCenter, in our sole discretion without notice. We
        will not be liable if for any reason all or any part of the ClientCenter is
        unavailable at any time or for any period. From time to time, we may restrict
        access to some parts of the Client Center, or the entire Client Center, to users,
        including registered users.
        <br />
        <br />
        To access the Client Center or some of the resources it offers, You may be asked
        to provide certain registration details or other information. It is a condition of
        Your use of the Client Center that all the information You provideon the Client
        Center is correct, current, and complete. You agree that all information You
        provide to register with this Client Center or otherwise, including but not
        limited to using any interactive features on the Client Center, isgoverned by our
        Privacy Policy, and You consent to all actions we take with respect to Your
        information consistentwith our Privacy Policy.
        <br />
        <br />
        If You choose, or are provided with, a user name, password, or any other piece of
        information as part of our security procedures, You must treat such information as
        confidential, and You must not disclose it to any other person orentity. You also
        acknowledge that Your account is personal to You and agree not to provide any
        other person withaccess to the Client Center or portions of it using Your user
        name, password, or other security information. You agree to notify us immediately
        of any unauthorized access to or use of Your user name or password or any
        othersimilar breach of security. You also agree to ensure that You exit from Your
        account at the end of each session. You should use particular caution when
        accessing Your account from a public or shared computer so that others are notable
        to view or record Your password or other personal information.
        <br />
        <br />
        We have the right to disable any user name, password, or other identifier, whether
        chosen by You or provided by us,at any time in our sole discretion, including
        without limitation if, in our opinion, You have violated any provision ofthese
        Terms of Use.
      </p>

      <h3 className="section-title">Non-Transferable Membership</h3>
      <p>
        Cingo and its licensors grant to you a personal, non-exclusive, non-transferable
        license to use and display the audio and visual information, documents, products
        and software contained in or made available through the Client Center(the
        "Content") solely for your own internal business purposes. All rights not
        expressly granted by Cingo to you arereserved to Cingo.
      </p>

      <h3 className="section-title">Intellectual Property Rights</h3>
      <p>
        The Client Center and its entire contents, features, and functionality (including
        but not limited to all information,software, text, displays, images, video, and
        audio, and the design, selection, and arrangement thereof) are owned bythe
        Company, its licensors, or other providers of such material and are protected by
        United States and international copyright, trademark, patent, trade secret, and
        other intellectual property or proprietary rights laws.
        <br />
        <br />
        These Terms of Use permit You to use the Client Center for Your personal,
        non-commercial use only. You must notreproduce, distribute, modify, create
        derivative works of, publicly display, publicly perform, republish,
        download,store, or transmit any of the material in the Client Center. You must not
        access or use for any commercial purposes any part of the Client Center or any
        services or materials available through the Client Center.
        <br />
        <br />
        If You print, copy, modify, download, or otherwise use or provide any other person
        with access to any part of the Client Center in breach of the Terms of Use, Your
        right to use the Client Center will stop immediately and You must, at our option,
        return or destroy any copies of the materials You have made. No right, title, or
        interest in or tothe Client Center or any content on the Client Center is
        transferred to You, and the Company reserves all rights not expressly granted. Any
        use of the Client Center not expressly permitted by these Terms of Use is a breach
        of these Terms of Use and may violate copyright, trademark, and other laws.
      </p>

      <h3 className="section-title">Restrictions</h3>
      <p>
        You are permitted to store, manipulate, analyze, reformat, print and display the
        Content only for your own internal business purposes. Unauthorized use or resale
        of the Client Center is expressly prohibited. You shall not copy,license, sell,
        transfer, make available, distribute, or assign this license or the Content to any
        third party. You shall not create internet "links" to the Client Center or "frame"
        or "mirror" any Content contained on, or accessible fromthe Client Center on any
        other server or Internet-attached device.
        <br />
        <br />
        You will use the Client Centers only for lawful purposes and in accordance with
        these Terms. You shall not (i)modify, copy or make derivative works based on Cingo
        Technology or the Client Center; (ii) disassemble, reverseengineer or decompile
        any Cingo Technology; or (iii) sell, sublicense, transfer or make available any
        Cingo Technology or the Client Center to any third party(ies); (iv) use any robot,
        spider, or other automatic device,process, or means to access the Client Center
        for any purpose, including monitoring or copying any of the materialon the Client
        Center; (vii) use any manual process to monitor or copy any of the material on the
        Client Center or forany other unauthorized purpose without our prior written
        consent; (viii) use any device, software, or routine thatinterferes with the
        proper working of the Client Center, (ix) introduce any viruses, Trojan horses,
        worms, logicbombs, or other material that is malicious or technologically harmful;
        (x) attempt to gain unauthorized access to,interfere with, damage, or disrupt any
        part of the Client Center, the server on which the Client Center is stored, or any
        server, computer, or database connected to the Client Center; (xi) attack the
        Client Center via a denial-of-Service attack or a distributed denial-of-Service
        attack; (xii) otherwise attempt to interfere with the proper working of the Client
        Center.
        <br />
        <br />
        Additionally, You shall not use the Client Center (xiii) in any way that violates
        any applicable federal, state, local, orinternational law or regulation
        (including, without limitation, any laws regarding the export of data or software
        toand from the US or other countries); (xiv) for the purpose of exploiting,
        harming, or attempting to exploit or harmminors in any way by exposing them to
        inappropriate content, asking for personally identifiable information,
        orotherwise; (xv) to transmit, or procure the sending of, any advertising or
        promotional material, including any “junkmail”, “chain letter”, “spam”, or any
        other similar solicitation; (xvi) to impersonate or attempt to impersonate
        theCompany, a Company employee, another user, or any other person or entity
        (including, without limitation, by usingemail addresses associated with any of the
        foregoing); (xvii) to engage in any other conduct that restricts or
        inhibitsanyone’s use or enjoyment of the Client Center, or which, as determined by
        us, may harm the Company or users ofthe Client Center or expose them to liability;
        (xviii) in any manner that could disable, overburden, damage, or impairthe Client
        Center or interfere with any other party’s use of the Client Center, including
        their ability to engage in realtime activities through the Client Center.
      </p>

      <h3 className="section-title">User Accounts</h3>
      <p>
        A user account is required to access the Client Center and may be accessed and
        used only by those authorizedindividuals who are registered with the Company. To
        open a user account you or your company must complete theregistration process by
        providing the Company with current, complete and accurate information as prompted
        by theregistration form. In registering for the Client Center, you and your
        company agrees to submit accurate, current andcomplete information about you and
        your organization and to promptly update such information in the event of
        anychanges. Should we suspect that such information is untrue, inaccurate, not
        current or incomplete, we have the rightto suspend or terminate your use of the
        Client Center. You must choose a personal, non-transferable password. User
        accounts cannot be "shared" or used by more than one individual. User licenses can
        be transferred to a new user onlyif a previous user becomes inactive and is unable
        to access the Client Center.
      </p>

      <h3 className="section-title">User Responsibilities</h3>
      <p>
        You are solely responsible for any and all activities that occur under your
        account and assuring that you exit or log-off from your account at the end of each
        session of use. You shall notify Cingo immediately of any unauthorized useof your
        password or account or any other breach of security that is known to or suspected
        by you. You agree to useyour best efforts to stop immediately any copying or
        distribution of Content known or suspected by you in the eventof a breach. Cingo
        is not responsible for any unauthorized access to or alteration of your
        transmissions or data, anymaterial, information or data sent or received whether
        the data is actually received by Cingo, any transactions entered into through the
        Client Center or any failure by you to abide by this Agreement.
      </p>

      <h3 className="section-title">Account Information and Data</h3>
      <p>
        The Company does not own any data, information or material that you submit to the
        Client Center ("Data"). TheCompany will not monitor, edit, or disclose any
        information regarding you or your account, including any Data,without your prior
        permission except in accordance with this Agreement or as may be required by law.
        In providingservices through the Client Center, we will provide all or part of the
        Data to our contractually obligated third parties. A full list, as amended from
        time to time, of the third party providers along with links to their respective
        domains where you can find their respective terms of service and privacy policies
        is included in Exhibit A attached hereto. We may access your account and/or its
        Data to respond to Client Center or technical problems or as stated in
        thisAgreement. You, not the Company, have sole responsibility for the accuracy,
        quality, integrity, legality, reliability, appropriateness and copyright of all
        Data, and Cingo is not responsible or liable for the deletion, correction,
        destruction, damage, loss or failure to store any Data.
      </p>

      <h3 className="section-title">Use, Storage and Other Limitations</h3>
      <p>
        Cingo reserves the right to establish or modify general practices and limits
        concerning use of the Client Center,including without limitation the maximum
        number of days that Content will be retained by the Client Center and the maximum
        storage space allotted on Cingo’s servers on your behalf.
      </p>

      <h3 className="section-title">User Conduct</h3>
      <p>
        You agree to abide by all applicable local, state, national and foreign laws,
        treaties and regulations in connectionwith the Client Center. In addition, you
        agree without limitation not to use the Client Center or information from the
        Client Center to:
      </p>
      <ol type="a">
        <li>
          send unsolicited or unauthorized advertising, promotional materials, junk mail,
          spam, chain letters, pyramid schemes, or any other form of duplicative or
          unsolicited messages, whether commercial or otherwise
        </li>
        <li>
          harvest, collect, gather or assemble information or data regarding other users
          including e-mail addresses, without their consent
        </li>
        <li>
          transmit through or post on the Client Center unlawful, harassing, libelous,
          abusive, harassing, tortuous, defamatory, threatening, harmful, invasive of
          another's privacy, vulgar, obscene orotherwise objectionable material of any
          kind or nature or which is harmful to minors in any way
        </li>
        <li>
          transmit any material that may infringe the intellectual property rights or
          other rights of thirdparties, including trademark, copyright or right of
          publicity
        </li>
        <li>
          transmit any material that contains software viruses or other harmful or
          deleterious computer code, files or programs such as trojan horses, worms, time
          bombs or cancelbots
        </li>
        <li>
          interfere with or disrupt servers or networks connected to the Client Center or
          violate the regulations, policies or procedures of such networks
        </li>
        <li>
          attempt to gain unauthorized access to the Client Center, other accounts,
          computer systems or networks connected to the Client Center through password
          mining or any other means, or{' '}
        </li>
        <li>
          harass or interfere with another user's use and enjoyment of the Client Center.{' '}
        </li>
      </ol>

      <h3 className="section-title">Monitoring and Enforcement; Termination</h3>
      <p>
        Any unauthorized access, use, copying, disclosure, distribution or sublicensing by
        you or with your aid or consent ofthe Client Center or Content or any related
        methods, algorithms, techniques or processes will be deemed a materialbreach of
        this Agreement. The Company at its sole discretion may terminate your account or
        use of the Client Center and remove and discard any Data within the Client Center
        if you breach or otherwise fail to comply with thisAgreement.
        <br />
        <br />
        Cingo will make available a file of your data within 30 days of termination if you
        request in writing at the time ofnotification of termination. Upon termination of
        an account your right to use such account and the Client Centerimmediately ceases
        and Cingo shall have no obligation except as stated above to maintain any Data
        stored in youraccount or to forward any Data to you or your designated third
        party.
        <br />
        <br />
        We have the right to (i) take appropriate legal action, including without
        limitation, referral to law enforcement, forany illegal or unauthorized use of the
        Client Center; (ii) terminate or suspend Your access to all or part of the
        ClientCenter for any or no reason, including without limitation, any violation of
        these Terms of Use.
        <br />
        <br />
        Without limiting the foregoing, we have the right to cooperate fully with any law
        enforcement authorities or courtorder requesting or directing us to disclose the
        identity or other information of anyone posting any materials on orthrough the
        Client Center. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS
        AFFILIATES,LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY
        ACTION TAKENBY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF,
        INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
      </p>

      <h3 className="section-title">Proprietary Rights</h3>
      <p>
        Except for the licenses granted herein, you have no right, title or interest in or
        to Cingo, the Client Center or anyContent of Cingo including without limitation
        documentation, stories, articles, text, images, and other multimediadata and all
        such right, title and interest shall remain exclusively with Cingo. Cingo and/or
        other Cingo products andClient Centers referenced herein are either trademarks or
        registered trademarks of Cingo.
      </p>

      <h3 className="section-title">Mutual Indemnification</h3>
      <p>
        You hereby indemnify and hold Cingo and its parent organizations, subsidiaries,
        affiliates, officers, directors,employees, attorneys and agents harmless from and
        against any and all claims, costs, damages, losses, liabilities andexpenses
        (including attorneys' fees and costs) arising out of or in connection with any
        unauthorized or improper useof the Client Center or Content or any breach of this
        Agreement by you.
        <br />
        <br />
        Cingo hereby indemnifies and holds you and your parent organizations,
        subsidiaries, affiliates, officers, directors,employees, attorneys and agents
        harmless from and against any and all claims, costs, damages, losses, liabilities
        andexpenses (including attorneys' fees and costs) arising out of or in connection
        with your proper and authorized use ofthe Client Center or Content.
      </p>

      <h3 className="section-title">Warranties</h3>
      <ol type="a">
        <li>
          CINGO WARRANTS THAT THE CLIENT CENTER PROVIDED WILL PERFORMSUBSTANTIALLY IN
          ACCORDANCE WITH THE FUNCTIONS DESCRIBED IN THE TERMS OFSERVICE AND APPLICABLE
          STATEMENT(S) OF WORK UNDER NORMAL USE ANDCIRCUMSTANCES.
        </li>
        <li>
          EXCEPT AS PROVIDED IN SUBSECTION (a) ABOVE: CINGO AND ITS LICENSORS MAKE
          NOREPRESENTATION, WARRANTY OR GUARANTY AS TO THE RELIABILITY,
          TIMELINESS,QUALITY, SUITABILITY, TRUTH, AVAILABILITY, ACCURACY OR COMPLETENESS
          OF THECLIENT CENTER OR ANY CONTENT. CINGO AND ITS LICENSORS DO NOT REPRESENT
          ORWARRANT THAT:
          <ol type="I">
            <li>
              THE USE OF THE CLIENT CENTER WILL BE SECURE, TIMELY, UNINTERRUPTED
              ORERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE,SOFTWARE,
              SYSTEM OR DATA
            </li>
            <li>THE CLIENT CENTER WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS</li>
            <li>ANY STORED DATA WILL BE ACCURATE OR RELIABLE</li>
            <li>
              THE QUALITY OF ANY PRODUCTS, CLIENT CENTERS, INFORMATION OR OTHERMATERIAL
              PURCHASED OR OBTAINED BY YOU THROUGH THE CLIENT CENTERWILL MEET YOUR
              REQUIREMENTS OR EXPECTATIONS
            </li>
            <li>ERRORS OR DEFECTS WILL BE CORRECTED OR</li>
            <li>
              THE CLIENT CENTER OR THE SERVER(S) THAT MAKE THE CLIENT CENTERAVAILABLE ARE
              FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR WILLBE AVAILABLE AND
              ACCESSIBLE 100% OF THE TIME. THE CLIENT CENTER ANDALL CONTENT IS PROVIDED TO
              YOU STRICTLY ON AN "AS IS" BASIS. ALLCONDITIONS, REPRESENTATIONS AND
              WARRANTIES WHETHER EXPRESS, IMPLIED,STATUTORY OR OTHERWISE INCLUDING WITHOUT
              LIMITATION ANY IMPLIEDWARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, ORNON-INFRINGEMENT OF THIRD PARTY RIGHTS, ARE HEREBY DISCLAIMED TO
              THEMAXIMUM EXTENT PERMITTED BY APPLICABLE LAW BY CINGO AND ITSLICENSORS
            </li>
          </ol>
        </li>
      </ol>

      <h3 className="section-title">Limitation of Liability</h3>
      <p>
        IN NO EVENT SHALL EITHER PARTY’S AGGREGATE LIABILITY EXCEED THE AMOUNT
        ACTUALLYPAID BY OR DUE FROM YOU (WHICHEVER IS GREATER) IN THE TWELVE (12) MONTH
        PERIODIMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM. IN NO EVENT
        SHALLEITHER PARTY AND/OR ITS LICENSORS BE LIABLE TO ANYONE FOR ANY INDIRECT,
        PUNITIVE,SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY
        TYPE ORKIND (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC
        ADVANTAGE)ARISING OUT OF OR IN ANY WAY CONNECTED WITH THIS CLIENT CENTER INCLUDING
        BUT NOTLIMITED TO YOUR USE OR INABILITY TO USE THE CLIENT CENTER OR FOR ANY
        CONTENTOBTAINED FROM OR THROUGH THE CLIENT CENTER, EVEN IF THE PARTY FROM
        WHICHDAMAGES ARE BEING SOUGHT OR SUCH PARTY'S LICENSORS HAVE BEEN PREVIOUSLY
        ADVISEDOF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL EITHER PARTY’S
        LICENSORS BELIABLE TO ANYONE FOR ANY DIRECT DAMAGES OF ANY TYPE OR KIND (INCLUDING
        LOSTPROFITS) OR FOR ANY INDIRECT, SPECIAL, EXEMPLARY OR CONSEQUENTIAL
        DAMAGES(INCLUDING LOST PROFITS) ARISING UNDER THIS AGREEMENT OR FROM
        PERFORMANCETHEREUNDER BASED IN CONTRACT, NEGLIGENCE, STRICT LIABILITY OR
        OTHERWISE, WHETHEROR NOT THEY HAD ANY KNOWLEDGE, ACTUAL OR CONSTRUCTIVE, THAT SUCH
        DAMAGESMIGHT BE INCURRED, OR FOR ANY INTERRUPTION, INACCURACY, ERROR OR OMISSION
        IN THECONTENT REGARDLESS OF CAUSE.
      </p>

      <h3 className="section-title">Additional Rights</h3>
      <p>
        Certain States and/or jurisdictions do not allow the exclusion of implied
        warranties or limitation of liability forincidental or consequential damages.
        Therefore, the exclusions set forth above may not apply to you.
      </p>

      <h3 className="section-title">Local Laws and Export Control</h3>
      <p>
        Cingo provides Client Centers and uses software and technology that may be subject
        to United States export controlsadministered by the U.S. Department of Commerce,
        the United States Department of Treasury Office of ForeignAssets Control or other
        U.S. agencies. You acknowledge and agree the Client Center will not be used and
        none ofthe underlying information, software or technology may be transferred or
        otherwise exported or re-exported toAfghanistan, Burma, Cuba, Iraq, Iran, Libya,
        Sudan or any other country which the United States embargos(collectively,
        "Embargoed Countries") or to or by a national or resident thereof or any person or
        entity on the U.S.Department of Treasury’s List of Specially Designated Nationals
        or the U.S. Department of Commerce’s Table ofDenial Orders (collectively,
        “Designated Nationals”). The lists of Embargoed Countries and Designated
        Nationalsare subject to change without notice. By using the Client Center, you
        represent and warrant that you are not locatedin, under the control of or are a
        national or resident of an Embargoed Country or Designated National. You agree
        tocomply strictly with all U.S. export laws, to not export or re-export any Cingo
        technology of any kind and acceptsole responsibility for illegal or unauthorized
        use. Cingo may use encryption technology that is subject to licensingrequirements
        under the Export Administration Regulations. For more information, see the Export
        AdministrationRegulations, 15 C.F.R. Parts 730-774. Cingo makes no representation
        of the Client Center being appropriate oravailable for use in other locations. If
        you use the Client Center from outside the United States of America you aresolely
        responsible and accept full responsibility for compliance with all applicable
        laws, including without limitationexport and import regulations of other countries
        and hold Cingo harmless for any illegal or unauthorized use of theClient Center.
        All diversion of the Content contrary to United States law is prohibited. None of
        the Content nor anyinformation acquired through the Client Center is or will be
        used for nuclear activities, chemical or biological weapons or missile projects
        unless specifically authorized in writing by the United States Government for
        suchpurposes and accepted in writing by Cingo prior to any such use.
      </p>

      <h3 className="section-title">Submissions</h3>
      <p>
        Cingo alone owns all right, title and interest, including all related intellectual
        property rights, any suggestions, ideas,feedback, recommendations or other
        information provided by you relating to the Client Center ("Submissions") andby
        submitting those items to Cingo you agree to assign such Submissions to Cingo at
        no charge. Cingo may use suchSubmissions as it deems appropriate in its sole
        discretion.
      </p>

      <h3 className="section-title">Notice</h3>
      <p>
        Cingo may give notice by means of a general notice through the Client Center,
        electronic mail to your e-mailaddress of record in Cingo's account information or
        by written communication sent by first class mail to youraddress of record in
        Cingo's account information. You may give notice to Cingo (such notice shall be
        deemed givenwhen received by Cingo) at any time by sending a letter delivered by a
        nationally recognized overnight deliveryClient Center prepaid to Cingo at the
        following address: Cingo Solutions, LLC, 491 North Bluff Street #106, Saint
        George, UT 84770.
      </p>

      <h3 className="section-title">Modification of Terms</h3>
      <p>
        Cingo reserves the right to change the terms and conditions of this Agreement or
        its policies relating to the ClientCenter at any time and notifying you by posting
        an updated version of this Agreement on the Client Center. You areresponsible for
        regularly reviewing this Agreement. Continued use of the Client Center after any
        such changesconstitutes your consent to such changes.
      </p>

      <h3 className="section-title">Changes and Updates</h3>
      <p>
        We may revise and update these Terms of Use from time to time in our sole
        discretion. All changes are effectiveimmediately when we post them and apply to
        all access to and use of the Client Center thereafter. Your continueduse of the
        Client Center following the posting of revised Terms of Use means that You accept
        and agree to thechanges.
        <br />
        <br />
        We may update the content of the Client Center from time to time, but its content
        is not necessarily complete or up-to-date. Any of the material on the Client
        Center may be out of date at any given time, and we are under noobligation to
        update such material.
      </p>

      <h3 className="section-title">Assignability</h3>
      <p>
        This Agreement may not be assigned except by Cingo (i) to a parent or subsidiary
        thereof or (ii) to an acquirer ofassets thereof. Any purported assignment in
        violation of this Section shall be void.
      </p>

      <h3 className="section-title">Beneficiaries</h3>
      <p>
        The rights and limitations in this Agreement are also for the benefit of Cingo's
        licensors, each of whom shall havethe right to enforce its rights hereunder
        directly and on its own behalf.
      </p>

      <h3 className="section-title">Dispute Resolution</h3>
      <p>
        Each party represents and warrants that it has met with or has had the opportunity
        to meet with legal counsel andenters into these Terms of Use voluntarily with full
        understanding of the terms and conditions. Any dispute arisingout of or relating
        to these Terms of Use including the alleged breach, termination, validity,
        interpretation andperformance thereof (“Dispute”) shall be resolved in the
        following manner:
      </p>
      <ol type="a">
        <li>
          <h3 className="section-title">Negotiation.</h3> Upon written notice of any
          Dispute from either party, the parties shall attempt to resolve itpromptly and
          in good faith by negotiation between executives who have authority to settle the
          Dispute and thisprocess shall be completed within 30 days (the “Negotiation”).
        </li>
        <li>
          <h3 className="section-title">Mediation.</h3> If the Dispute has not been
          resolved by Negotiation within 30 days, then a “Notice ofMediation” shall be
          served by either party signifying that the Negotiation was unsuccessful and to
          commence theMediation process (the “Mediation”). The parties shall agree on a
          qualified mediator from the Alternative DisputeResolution Department (“ADR”) of
          the Utah State Courts; however, if they cannot agree within 14 days then theADR
          shall appoint a mediator. The Mediation session shall be held within 45 days of
          the retention of the mediator, and last for at least one full Mediation day,
          before any party has the option to withdraw from the process. The partiesmay
          agree to continue the Mediation process beyond one day, until there is a
          settlement agreement, or the mediatorstates that there is no reason to continue
          because of an impasse that cannot be overcome and sends a “Notice ofImpasse” to
          the other party. All reasonable efforts will be made to complete the Mediation
          within 30 days of the firstMediation session. During the course of the
          Mediation, no party can assert the failure to fully comply with Section16(a) as
          a reason not to proceed or to delay the Mediation. The service of the Notice of
          Mediation shall stay therunning of any applicable statute of limitations
          regarding the Dispute until 30 days after the parties agree that theMediation is
          concluded or the mediator issues a Notice of Impasse. Each side shall bear an
          equal share of theMediation costs unless the parties agree otherwise. The
          Negotiation and/or Mediation processes shall be confidentialand all
          communications, both written and oral, during Negotiation and/or Mediation are
          confidential and shall betreated as settlement negotiations for purposes of
          applicable rules of evidence; however, documents generated in theordinary course
          of business prior to the Dispute, that would otherwise be discoverable, do not
          become confidentialbecause they are used in the Negotiation and/or Mediation
          process.
        </li>
        <li>
          <h3 className="section-title">Arbitration.</h3> Any Dispute not resolved through
          Negotiation or Mediation in accordance with Section 16(a)and (b) shall be
          resolved by final and binding arbitration in accordance with the Commercial Law
          rules of theAmerican Arbitration Association (the “Arbitration”). The
          Arbitration will be held in Salt Lake City, Utah using onearbitrator, unless the
          Dispute exceeds one million US dollars in which case there shall be three
          neutral arbitrators, asa panel. The arbitrators may award costs and/or
          attorneys’ fees to the prevailing party. The parties understand andagree that
          the Arbitration is final and binding and that they are waiving their rights to
          other resolution processes(such as court action or administrative proceeding).
        </li>
      </ol>

      <h3 className="section-title">General</h3>
      <p>
        This Agreement will be governed by the Law of the State of Utah without regard to
        the choice or conflicts of lawprovisions of any jurisdiction. The English language
        version of this Agreement controls its interpretation. If anyprovision of this
        Agreement is held to be invalid or unenforceable by a court of competent
        jurisdiction suchprovision(s) shall be construed as nearly as possible to reflect
        the intentions of the invalid or unenforceableprovision(s), with all other
        provisions remaining in full force and effect. No joint venture, partnership,
        employmentor agency relationship exists between you and Cingo as a result of this
        Agreement or use of the Client Center. Thefailure of Cingo to enforce any right or
        provision in this Agreement shall not constitute a waiver of such right
        orprovision unless acknowledged and agreed to by Cingo in writing. The Terms of
        Use, Terms of Service and ourPrivacy Policy constitute the sole and entire
        agreement between You and Company regarding the Client Center andsupersede all
        prior and contemporaneous understandings, negotiations, discussions, agreements,
        representations, andwarranties, both written and oral, regarding the Client
        Center.
      </p>
      <div>
        <b>EXIBIT A</b>
        <br />
        <b>THIRD PARTY PROVIDERS</b>

        <table>
          <tbody>
            <tr>
              <th>Name:</th>
              <th>URL:</th>
            </tr>
            <tr>
              <td>
                Amazon Web Services, Inc. <br />
                Duo Security, Inc. <br />
                Microsoft Corporation <br />
                AppRiver, LLC <br />
                KNOWBE4, INC. <br />
                Citrix Systems, Inc. <br />
                Team Viewer LLC <br />
                Google Commerce Limited <br />
                Stripe LLC <br />
                JumpCloud LLC
              </td>
              <td>
                https://aws.amazon.com <br />
                https://duo.com/ <br />
                https://www.office.com/ <br />
                https://www.appriver.com/ <br />
                https://www.knowbe4.com/ <br />
                https://www.citrix.com/ <br />
                https://www.teamviewer.com/en-us/eula/ <br />
                https://cloud.google.com/ <br />
                https://stripe.com/privacy <br />
                https://jumpcloud.com/legal
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Wrapper>
  );
};

export default Terms;
