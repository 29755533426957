import moment from 'moment';
import React from 'react';
import { Field } from 'formik';
import { DatePicker, Form } from 'antd';
import { datePickerFormat } from '~/lib/utils/dateTimeHelpers';

const { RangePicker } = DatePicker;
const { Item } = Form;

// Sets the color of warning: 'success', 'warning', 'error', 'validating'
const status = 'warning';

/* 

  This component doesn't extract the field prop because it is representing 2 fields.
  It needs the startDate field name and endDate field name passed.

  Set Defaults in this way: 
    defaultValue={ [ moment, moment] }

*/
const AntRangePicker = ({ form, label, startName, endName, ...rest }) => {
  const errorExistsAndFieldTouched =
    (!!form.errors[startName] && !!form.touched[startName]) ||
    (!!form.errors[endName] && !!form.touched[endName]);

  return (
    <Item
      label={label}
      validateStatus={errorExistsAndFieldTouched ? status : null}
      help={
        errorExistsAndFieldTouched ? form.errors[startName] || form.errors[endName] : null
      }
    >
      <RangePicker
        ranges={{
          Today: [moment(), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')]
        }}
        format={datePickerFormat}
        onChange={([start, end]) => {
          form.setFieldValue(startName, start ? start.valueOf() : null);
          form.setFieldValue(endName, end ? end.valueOf() : null);
        }}
        value={[
          form.values[startName] ? moment(form.values[startName]) : null,
          form.values[endName] ? moment(form.values[endName]) : null
        ]}
        {...rest}
      />
    </Item>
  );
};

const FormikRangePicker = props => <Field component={AntRangePicker} {...props} />;

export default FormikRangePicker;
