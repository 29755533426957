import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useGlobal } from 'reactn';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import React from 'react';
import NavMain from '~/app/App/com/NavMain';
import TopBar from '~/app/App/com/TopBar';
import PasswordSecurity from '~/pages/PasswordSecurity';
import Compliance from '~/pages/Compliance';
import Terms from '~/pages/Terms';
import Privacy from '~/pages/Privacy';
import Tickets from '~/pages/Tickets';
import Account from '~/pages/Account';
import AppRiver from '~/pages/AppRiver';
import Dashboard from '~/pages/Dashboard';
import KnowBefore from '~/pages/KnowBefore';
import Nessus from '~/pages/Nessus';

const ROUTES = [
  {
    path: '/account-settings',
    component: Account,
    permissions: ['admin', 'user', 'Client User']
  },
  {
    path: '/compliance',
    component: Compliance,
    permissions: ['admin', 'user', 'Client User']
  },
  {
    path: '/dashboard',
    component: Dashboard,
    permissions: ['admin', 'user', 'Client User'],
    defaultPageOf: ['admin', 'user', 'Client User']
  },
  {
    path: '/email-security',
    component: AppRiver,
    permissions: ['admin', 'user', 'Client User']
  },
  {
    path: '/network-security',
    component: Nessus,
    permissions: ['admin', 'user', 'Client User']
  },
  {
    path: '/password-security',
    component: PasswordSecurity,
    permissions: ['admin', 'user', 'Client User']
  },
  {
    path: '/tickets',
    component: Tickets,
    permissions: ['admin', 'user', 'Client User']
  },
  {
    path: '/training',
    component: KnowBefore,
    permissions: ['admin', 'user', 'Client User']
  },
  {
    path: '/terms',
    component: Terms,
    permissions: ['admin', 'user', 'Client User']
  },
  {
    path: '/privacy',
    component: Privacy,
    permissions: ['admin', 'user', 'Client User']
  }
  // {
  //   path: '/users/:id',
  //   component: User,
  //   permissions: ['admin', 'user', 'Client User'],
  //   defaultPageOf: ['admin', 'user', 'Client User']
  // },
  // {
  //   path: '/users',
  //   component: Users,
  //   permissions: ['admin', 'user', 'Client User']
  // }
];

const routes = ({ roles }) => {
  const location = useLocation();
  const [loggedIn] = useGlobal('loggedIn');
  const [currentClientUser] = useGlobal('currentClientUser');

  const activeClient = currentClientUser?.client?.stripeInfo?.billingStatus === 'active';
  const defaultPageRoles = [].concat(
    ...ROUTES.filter(({ defaultPageOf }) => !!defaultPageOf).map(
      ({ defaultPageOf }) => defaultPageOf
    )
  );
  const defaultPageRole =
    roles.find(role => role !== 'Client User' && defaultPageRoles.includes(role)) ||
    'Client User';
  const accountRoute = ROUTES.find(({ path }) => path === '/account-settings');
  const defaultPage = activeClient
    ? {
        ...ROUTES.find(
          ({ defaultPageOf }) =>
            !!defaultPageOf && defaultPageOf.includes(defaultPageRole)
        ),
        path: '/'
      }
    : accountRoute;

  const routesWithDefault = activeClient ? [...ROUTES, defaultPage] : [accountRoute];
  const transitionKey = location.key || location.pathname;

  return (
    <>
      <NavMain
        roles={roles}
        permissions={routesWithDefault.reduce(
          (a, { path, permissions }) => ({
            ...a,
            [path]: permissions
          }),
          {}
        )}
        path={location.pathname}
      />
      <div className="page-wrap">
        <TopBar />
        <SwitchTransition>
          <CSSTransition key={transitionKey} classNames="fade" timeout={400}>
            <Switch location={location}>
              {loggedIn ? (
                routesWithDefault
                  .filter(({ permissions }) => permissions.some(p => roles.includes(p)))
                  .map(({ path, component }) => (
                    <Route key={path} exact path={path} component={component} />
                  ))
              ) : (
                <Redirect to={{ pathname: '/' }} />
              )}

              <Route component={defaultPage.component} />
            </Switch>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </>
  );
};

export default routes;
