import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import {
  ApiOutlined,
  BookOutlined,
  HomeOutlined,
  MailOutlined,
  TeamOutlined,
  LockOutlined,
  DesktopOutlined,
  SettingOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import logo from '~/lib/images/logos/cingo-logo.svg';
import SimpleLogo from '~/lib/images/logos/simple-logo.svg';
import './NavMain.scss';

const { Sider } = Layout;

const NavMain = ({ roles, permissions, path }) => {
  const [menuOpen, setMenuOpen] = useGlobal('menuOpen');
  const [, setLoggedIn] = useGlobal('loggedIn');
  const [breakpointMet, setBreakpointMet] = useState(false);

  const someInclude = p =>
    !!permissions[p] && permissions[p].some(role => roles.includes(role));

  const menuItem = (p, text, Icon) =>
    someInclude(p) && (
      <Menu.Item key={p} icon={<Icon />}>
        <Link to={p}>{text}</Link>
      </Menu.Item>
    );

  return (
    <Sider
      className={`nav-main${breakpointMet ? ' force-broken' : ''}`}
      collapsible
      onCollapse={() => setMenuOpen(!menuOpen)}
      width="20%"
      breakpoint="x1"
      onBreakpoint={broken => setBreakpointMet(broken)}
      // collapsedWidth="60px"
      collapsed={menuOpen}
    >
      {!menuOpen ? (
        <div className="logo" style={{ backgroundImage: `url(${logo})` }} />
      ) : (
        <div className="logo" style={{ backgroundImage: `url(${SimpleLogo})` }} />
      )}

      <Menu
        defaultSelectedKeys={[path]}
        selectedKeys={[path]}
        mode="inline"
        theme="dark"
        defaultOpenKeys={['dashboard']}
        // inlineCollapsed={!menuOpen}
      >
        {menuItem('/dashboard', 'Dashboard', HomeOutlined)}
        {menuItem('/network-security', 'Firewall Security', DesktopOutlined)}
        {menuItem('/email-security', 'Email Security', MailOutlined)}
        {menuItem('/training', 'Employee Security', TeamOutlined)}
        {menuItem('/password-security', 'Password Security', LockOutlined)}
        {menuItem('/tickets', 'Tickets', ApiOutlined)}
        {menuItem('/compliance', 'Compliance', BookOutlined)}
        {menuItem('/account-settings', 'Account Settings', SettingOutlined)}

        <Menu.Item
          onClick={() => setLoggedIn(false)}
          key="/logout"
          icon={<LogoutOutlined />}
        >
          {/* <div>Sign out</div> */}
          <Link to="/logout">Sign Out</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default NavMain;
