import React, { useRef } from 'react';
import { Table } from 'antd';
import money from '~/lib/utils/moneyUtils';
import getColumnSearchProps from '~/lib/utils/tableUtils';
import './Invoices.scss';

const Invoices = ({ invoices }) => {
  const searchInputEl = useRef(null);
  const columns = [
    {
      title: 'Invoice',
      dataIndex: 'invoiceNumber',
      ...getColumnSearchProps('invoiceNumber', searchInputEl),
      render: (_, record) => <a href={record.link}>{record.invoiceNumber}</a>
    },
    {
      title: 'Due',
      dataIndex: 'amountDue',
      render: amount => money(amount * 100),
      sorter: (a, b) => a.amountDue - b.amountDue
    },
    {
      title: 'Paid',
      dataIndex: 'amountPaid',
      render: amount => money(amount * 100),
      sorter: (a, b) => a.amountPaid - b.amountPaid
    },
    {
      title: 'Paid On',
      dataIndex: 'paidOn',
      render: paidDate => paidDate
    },
    {
      title: 'Status',
      dataIndex: 'status'
    }
  ];
  return (
    <Table
      className="invoice-table"
      dataSource={invoices}
      columns={columns}
      rowKey={({ id }) => id}
      rowClassName="row"
    />
  );
};

export default Invoices;
