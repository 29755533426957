import React from 'react';
import { Field } from 'formik';
import { Checkbox } from 'antd';

// Ant design checkbox.
const AntCheckbox = ({ field, form, label, ...rest }) => {
  const { value, ...fieldRest } = field;

  return (
    <Checkbox
      {...fieldRest}
      onChange={event => form.setFieldValue(field.name, event.target.checked)}
      {...rest}
      defaultChecked={form.values[field.name]}
      checked={rest.checked || form.values[field.name]}
    >
      {label}
    </Checkbox>
  );
};
// Formik radio input wrapper.
const FormikCheckbox = props => (
  <Field type="checkbox" component={AntCheckbox} {...props} />
);

export default FormikCheckbox;
