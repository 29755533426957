import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Spinner } from '~/shared/elements';
import ApiError from '~/shared/errors/ApiError';
import PasswordSecurity from './PasswordSecurity';

const CLIENT_USERS = gql`
  {
    ownClientUsers {
      id
      name
      email
      phone
      roles
      status
      jobTitle
      passwordNeverExpires
      passwordExpired
      passwordExpirationDate
      badLoginAttempts
    }
  }
`;

const PasswordSecurityData = props => {
  const { data, error, loading } = useQuery(CLIENT_USERS);

  if (loading) return <Spinner />;
  if (error) return <ApiError error={error} />;

  const { ownClientUsers } = data;

  return <PasswordSecurity clientUsers={ownClientUsers} {...props} />;
};

export default PasswordSecurityData;
