import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CreateCreditCardPayment from './CreateCreditCardPayment';
import ApiError from '~/shared/errors/ApiError';
import { Spinner } from '~/shared/elements';

const ADD_CREDIT_CARD_PAYMENT_METHOD = gql`
  mutation($creditCardAttributes: CreditCardAttributes!) {
    addCreditCardPaymentMethod(creditCardAttributes: $creditCardAttributes) {
      id
      status
      lastFour
      name
      paymentType
    }
  }
`;

const STRIPE_PUBLIC_KEY = gql`
  {
    stripePublicKey
  }
`;

const CreateCreditCardPaymentData = props => {
  const [addCreditCardPaymentMethod] = useMutation(ADD_CREDIT_CARD_PAYMENT_METHOD);
  const { data, error, loading } = useQuery(STRIPE_PUBLIC_KEY);
  if (loading) return <Spinner />;
  if (error) return <ApiError error={error} />;
  const { stripePublicKey } = data;
  const stripePromise = loadStripe(stripePublicKey);
  return (
    <Elements stripe={stripePromise}>
      <CreateCreditCardPayment
        {...props}
        addCreditCardPaymentMethod={addCreditCardPaymentMethod}
      />
    </Elements>
  );
};

export default CreateCreditCardPaymentData;
