import gql from 'graphql-tag';

// Fragments are spread using its assigned <NAME> not its variable name
// Example: fragment <NAME> on <Type>

export const TICKET_FRAGMENT = gql`
  fragment TICKET_FRAGMENT on Ticket {
    id
    ticketNumber
    category
    status
    priority
    createdAt
    summary
    subject
    archived
    durationEnd
    teamId
    attachments {
      id
      fileName
      docType
      link
      status
      createdAt
    }
    workstation {
      id
      workstationNumber
    }
    externalComments {
      id
      comment
      createdAt
      user {
        id
        name
        image
      }
      clientUser {
        id
        name
      }
    }
    client {
      id
      companyName
      accountNumber
      firstContact {
        id
        name
        phone
        email
      }
    }
    clientUser {
      id
      name
    }
    user {
      id
      name
      image
    }
  }
`;

export const USER_DETAILS = gql`
  fragment USER_DETAILS on User {
    id
    name
    firstName
    lastName
    email
    phone
    roles
    status
  }
`;
