import React from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Spinner } from '~/shared/elements';
import ApiError from '~/shared/errors/ApiError';
import DNSFilter from './DNSFilter';

const DNS_FILTER_NETWORKS_AND_USER_AGENTS = gql`
  {
    dnsFilterNetworks {
      id
      name
    }
    dnsFilterUserAgents {
      id
      name
    }
  }
`;

const DNS_FILTER_TOP_DOMAINS = gql`
  query($params: DNSFilterTrafficReportParams!) {
    dnsFilterTopDomains(params: $params) {
      total
      totalCount
      records {
        domain
        categories
        total
      }
    }
    dnsFilterTopCategories(params: $params) {
      totalCount
      records {
        categories
        total
      }
    }
    dnsFilterTotalRequests(params: $params)
  }
`;

const DOMAIN_TICKETS_CREATE = gql`
  mutation($domains: [String]!, $allow: Boolean!) {
    domainTicketsCreate(domains: $domains, allow: $allow) {
      id
    }
  }
`;

const DNSFilterData = props => {
  const [domainTicketsCreate] = useMutation(DOMAIN_TICKETS_CREATE);
  const { data, error, loading } = useQuery(DNS_FILTER_NETWORKS_AND_USER_AGENTS);
  const [
    loadTrafficReports,
    { called, loading: topDomainsLoading, data: topDomainsData }
  ] = useLazyQuery(DNS_FILTER_TOP_DOMAINS, { variables: {} });

  if (loading) return <Spinner />;
  if (error) return <ApiError error={error} />;

  const { dnsFilterNetworks, dnsFilterUserAgents } = data;
  const {
    dnsFilterTopDomains: {
      records: dnsFilterTopDomains = [],
      total: totalTopDomains,
      totalCount
    } = {},
    dnsFilterTopCategories = {},
    dnsFilterTotalRequests
  } = topDomainsData || {};

  return (
    <DNSFilter
      loadTrafficReports={loadTrafficReports}
      dnsFilterNetworks={dnsFilterNetworks}
      dnsFilterUserAgents={dnsFilterUserAgents}
      dnsFilterTopDomains={dnsFilterTopDomains}
      dnsFilterTopCategories={dnsFilterTopCategories}
      dnsFilterTotalRequests={dnsFilterTotalRequests}
      topDomainsLoading={topDomainsLoading}
      totalTopDomains={totalTopDomains}
      totalCount={totalCount}
      domainTicketsCreate={domainTicketsCreate}
      {...props}
    />
  );
};

export default DNSFilterData;
