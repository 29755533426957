import React, { useEffect } from 'react';
import { useGlobal } from 'reactn';
import PublicRoutes from '~/routes/PublicRoutes';
import PrivateRoutes from '~/routes/PrivateRoutes';
import Spinner from '~/shared/elements/Spinner';

// global styles.
import 'normalize.css';
import 'antd/dist/antd.css';
import 'ant-design-pro/dist/ant-design-pro.css';
import './App.scss';

const App = ({ foundCurrentClientUser, refetch }) => {
  const [loggedIn, setLoggedIn] = useGlobal('loggedIn');
  const [roles, setRoles] = useGlobal('roles');
  const [currentClientUser, setCurrentClientUser] = useGlobal('currentClientUser');

  const { roles: roleStrings = [] } = foundCurrentClientUser || {};

  useEffect(() => {
    if (!loggedIn && !!foundCurrentClientUser && !currentClientUser) setLoggedIn(true);
    if (loggedIn && !!foundCurrentClientUser) {
      if (!currentClientUser) setCurrentClientUser(foundCurrentClientUser);
      if (!roles.length) setRoles(roleStrings);
    }
    if (loggedIn && !foundCurrentClientUser) refetch();
  }, [loggedIn, foundCurrentClientUser]);

  if (!['Client User'].some(r => roleStrings.includes(r)) && !loggedIn)
    return <PublicRoutes />;
  // eslint-disable-next-line
  if (!loggedIn) return <Spinner />;
  return <PrivateRoutes roles={roleStrings} />;
};

export default App;
