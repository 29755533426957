import React from 'react';
import { useGlobal } from 'reactn';
import { Spinner } from '~/shared/elements';
import ApiError from '~/shared/errors/ApiError';
import { useFetch } from '~/lib/utils/hooks';
import KnowBefore from './KnowBefore';

const KnowBeforeData = props => {
  const [currentClientUser] = useGlobal('currentClientUser');
  const { knowBeforeKey } = currentClientUser?.client ?? {};

  const { loading: accountLoading, error: accountError, data: account = {} } = useFetch(
    'https://us.api.knowbe4.com/v1/account',
    {
      cors: 'no-cors',
      headers: {
        Authorization: `Bearer: ${knowBeforeKey}`,
        'Content-Type': 'application/json'
      }
    },
    { skip: !knowBeforeKey }
  );

  if (accountLoading || !account) return <Spinner />;
  if (accountError) return <ApiError error={accountError} />;

  return <KnowBefore account={account} {...props} />;
};

export default KnowBeforeData;
