import React from 'react';
import { Field } from 'formik';
import { Input, Form } from 'antd';

const { Item } = Form;
const { TextArea } = Input;

const AntInput = ({ field, form, inputType = 'input', ...rest }) => {
  const errorExistsAndFieldTouched =
    !!form.errors[field.name] && !!form.touched[field.name];
  return (
    <Item
      label={rest.label}
      validateStatus={errorExistsAndFieldTouched ? 'warning' : null}
      help={errorExistsAndFieldTouched ? form.errors[field.name] : null}
      hasFeedback
    >
      {inputType === 'input' || inputType === 'number' || inputType === 'password' ? (
        <Input
          {...field}
          onBlur={({ target }) => {
            if (inputType === 'number') form.setFieldValue(field.name, +target.value);
            else form.setFieldValue(field.name, target.value.trim());
          }}
          {...rest}
          type={inputType}
          id={field.name}
        />
      ) : (
        <TextArea
          {...field}
          {...rest}
          id={field.name}
          onBlur={({ target }) => form.setFieldValue(field.name, target.value.trim())}
          autoSize={{ minRows: 4, maxRows: 6 }}
        />
      )}
    </Item>
  );
};

const FormikInput = props => <Field component={AntInput} {...props} />;

export default FormikInput;
