import React from 'react';
import moment from 'moment';
import { Field } from 'formik';
import { DatePicker, Form } from 'antd';

const { Item } = Form;

// Sets the color of warning: 'success', 'warning', 'error', 'validating'
const status = 'warning';

/* ...field passes it's own onChange. The order below over rides field.onChange but allows
for ...rest to over ride default */
const AntDatePicker = ({ field: tempField, form, label, ...rest }) => {
  // Added this line because formik calls onBlur when the field changes and it throws
  // an error. If this is a problem delete line below and above alias for field
  const { onBlur, ...field } = tempField;
  const errorExistsAndFieldTouched =
    !!form.errors[field.name] && !!form.touched[field.name];

  return (
    <Item
      label={label}
      validateStatus={errorExistsAndFieldTouched ? status : null}
      help={errorExistsAndFieldTouched ? form.errors[field.name] : null}
    >
      <DatePicker
        {...field}
        onChange={time => form.setFieldValue(field.name, time ? time.valueOf() : null)}
        value={form.values[field.name] ? moment(form.values[field.name]) : null}
        {...rest}
      />
    </Item>
  );
};

const FormikDatePicker = props => <Field component={AntDatePicker} {...props} />;

export default FormikDatePicker;
