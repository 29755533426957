import React from 'react';
import { Wrapper } from '~/shared/elements';
import './Privacy.scss';

const Privacy = () => {
  return (
    <Wrapper className="page privacy">
      <h1>Privacy Statement</h1>
      <p>
        Cingo Solutions, LLC has created this privacy statement ("Statement") in order to
        demonstrate ourcommitment to customer privacy. Privacy on the Cingo web site at
        cingo.center (the "Site") is of greatimportance to us. As we gather important
        information from our visitors and customers we haveestablished this Statement to
        communicate our information gathering and dissemination practices. Cingoreserves
        the right to change this Statement from time to time at its discretion.
      </p>
      <h3 className="section-title">Collected Information</h3>
      <p>
        We require customers who register to use the services available through the Site
        (the "Services") toprovide us contact information such as their name, company
        name, address, phone number and e-mailaddress and financial qualification and
        billing information such as billing name and address, credit cardnumber and the
        number of users within the organization accessing the Services. Cingo uses
        theinformation we collect to set up Services for individuals and their
        organizations. We may also use theinformation to contact customers to discuss
        customer interest in our company and the Services we provideand to send
        information regarding our company such as promotions and events. Customers are
        invited toreceive an email newsletter by providing an email address. Customer
        email addresses and any personalcustomer information will not be distributed or
        shared with any third parties. Customers can opt out ofbeing contacted by us, or
        receiving such information from us, at any time by notifying Cingo
        atcingo.solutions/contact. Separately, customers are also asked to provide an
        email address whenregistering for the Service, in order to receive a username and
        password. We may also email informationregarding updates to the Service or
        company. Again, email will not be distributed or shared andcustomers can opt out
        of receiving communications by notifying Cingo at cingo.solutions/contact at
        anytime. Except as we explicitly state in the Cingo Terms of Use, we do not
        disclose to any third-party theinformation you provide. All financial and billing
        information we collect through the Site is used solely tocheck the qualifications
        of prospective customers and to bill for Services. This billing information is
        notused by Cingo for marketing or promotional purposes. Cingo uses a third-party
        intermediary to managethe credit card processing. This intermediary is solely a
        link in the distribution chain and is not permittedto store, retain or use the
        information provided except for the sole purpose of credit card
        processing.Customers of the Service will use the Site to host data and information
        ("Data"). Cingo will not review,share, distribute, print or reference any such
        Data except as provided in the Cingo Terms of Use or as maybe required by law.
        Individual records may at times be viewed or accessed only for the purpose
        ofresolving a problem, support issue or suspected violation of the Terms of Use or
        as may be required bylaw. You are responsible for maintaining the confidentiality
        and security of your user registration andpassword. Cingo may also collect certain
        information from visitors to and customers of the Site such asInternet addresses.
        This information is kept to help with technical problems and to administer
        andimprove the Service. We may also track and analyze non-identifying and
        aggregate usage and volumestatistical information from our visitors and customers
        and provide such information to third parties.
      </p>

      <h3 className="section-title">Cookies</h3>
      <p>
        When you interact with the Site we strive to make that experience easy and
        meaningful. When you cometo the Site our server sends a cookie to your computer.
        Cookies are files that web browsers place on acomputer's hard drive and are used
        to tell us whether customers and visitors have visited the Sitepreviously. Cookies
        do not identify you personally; they only recognize your browser. Unless you
        chooseto identify yourself to us, you remain anonymous to us. Cookies come in two
        types: session andpersistent. Session cookies exist only during an online session.
        They disappear from your computer whenyou close your browser software or turn off
        your computer. Persistent cookies remain in your computerafter you've closed your
        browser or turned off your computer. They include information like a
        uniqueidentifier for your browser. We use session cookies containing encrypted
        information to allow the systemto uniquely identify you while you are logged in.
        Session cookies help us make sure you are who you sayyou are after you've logged
        in and are required in order to use our application. Cingo uses persistent cookies
        that only we can read. We use them to identify you as a Cingo customer. We’re very
        carefulabout the security and confidentiality of the information stored in
        persistent cookies. For example, we donot store account numbers or passwords in
        persistent cookies.
      </p>

      <h3 className="section-title">Third-Party Sites</h3>
      <p>
        The Site contains links to other web sites. Cingo is not responsible for the
        privacy practices or the contentof these other web sites. Customers and visitors
        will need to check the policy statement of these othersweb sites to understand
        their policies. It is the responsibility of the user to keep their private
        informationsecure and confidential.
      </p>

      <h3 className="section-title">Security</h3>
      <p>
        Our Site has security measures in place to help protect against the loss, misuse
        and alteration of the Dataunder our control. When our Site is accessed, Secure
        Socket Layer (SSL) technology protects yourinformation using both server
        authentication and data encryption to help ensure that Data is safe, secureand
        available only between you and Cingo. Cingo also implements advanced security
        methods based ondynamic data and encoded session identifications and hosts the
        Site in a secure server environment thatuses a firewall and other advanced
        technology to prevent interference or access from intruders. Finally,Cingo
        provides unique user names and passwords you must enter each time you log in.
        These safeguardshelp prevent unauthorized access, maintain data accuracy, and
        ensure the appropriate use of Data.
      </p>

      <h3 className="section-title">Opt-Out Policy</h3>
      <p>
        Cingo offers its visitors and customers a means to choose how we may use
        information provided. If at anytime after registering for information or ordering
        Services you change your mind about receivinginformation from us, send us a
        request specifying your new choice to support@cingo.solutions.
      </p>

      <h3 className="section-title">Correcting & Updating Your Information</h3>
      <p>
        Customers can update or remove their Data at any time. We provide users access to
        information that wemaintain about them, such as unique identifier information
        (e.g., customer number), transactioninformation (e.g., dates on which customers
        made purchases, amounts and types of purchases), financialinformation (e.g.,
        credit card account information), and contact information (e.g., name, address,
        andtelephone number) on the Site. Users may access and correct inaccuracies in
        this information by calling833-246-4648 and can view their updated profile to
        confirm the edits have been made.
      </p>

      <h3 className="section-title">Product Ordering</h3>
      <p>
        If you order products from us using the Site, we will use the personal information
        you provide to process, fill and obtain payment for the order. We may also use the
        information to provide you with product updates and other product-related
        information. We do not share your personal information with outside parties except
        to the extent necessary to complete and process the payment for such order or as
        otherwise provided in this Privacy Statement.
      </p>
    </Wrapper>
  );
};

export default Privacy;
