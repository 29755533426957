import React from 'react';
import { useGlobal } from 'reactn';
import { Spinner } from '~/shared/elements';
import ApiError from '~/shared/errors/ApiError';
import { useFetch } from '~/lib/utils/hooks';
import KnowBefore from './KnowBefore';

const KnowBeforeData = props => {
  const [currentClientUser] = useGlobal('currentClientUser');
  const { knowBeforeKey } = currentClientUser?.client ?? {};

  const { loading: accountLoading, error: accountError, data: account = {} } = useFetch(
    'https://us.api.knowbe4.com/v1/account',
    {
      headers: {
        Authorization: `Bearer: ${knowBeforeKey}`,
        'Content-Type': 'application/json'
      }
    },
    { skip: !knowBeforeKey }
  );

  const {
    loading: phishingCampaignsLoading,
    error: phishingCampaignsError,
    data: phishingCampaigns = []
  } = useFetch(
    'https://us.api.knowbe4.com/v1/phishing/campaigns',
    {
      headers: {
        Authorization: `Bearer: ${knowBeforeKey}`,
        'Content-Type': 'application/json'
      }
    },
    { skip: !knowBeforeKey }
  );

  const { loading: usersLoading, error: usersError, data: users = [] } = useFetch(
    'https://us.api.knowbe4.com/v1/users',
    {
      headers: {
        Authorization: `Bearer: ${knowBeforeKey}`,
        'Content-Type': 'application/json'
      }
    },
    { skip: !knowBeforeKey }
  );

  const {
    loading: traniningCampaignsLoading,
    error: traniningCampaignsError,
    data: traniningCampaigns = []
  } = useFetch(
    'https://us.api.knowbe4.com/v1/training/campaigns',
    {
      headers: {
        Authorization: `Bearer: ${knowBeforeKey}`,
        'Content-Type': 'application/json'
      }
    },
    { skip: !knowBeforeKey }
  );

  if (
    accountLoading ||
    !account ||
    phishingCampaignsLoading ||
    usersLoading ||
    traniningCampaignsLoading
  )
    return <Spinner />;
  if (accountError || phishingCampaignsError || usersError || traniningCampaignsError)
    return (
      <ApiError
        error={
          accountError || phishingCampaignsError || usersError || traniningCampaignsError
        }
      />
    );

  return (
    <KnowBefore
      account={account}
      phishingCampaigns={phishingCampaigns}
      users={users}
      traniningCampaigns={traniningCampaigns}
      {...props}
    />
  );
};

export default KnowBeforeData;
