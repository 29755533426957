import React, { useState, useEffect } from 'react';
import { Table, Radio } from 'antd';
import { Wrapper } from '~/shared/elements';
import DNSFilter from '~/shared/page-coms/DNSFilter';
import './Nessus.scss';

const SEVERITY_MAP = {
  0: 'Info',
  1: 'Low',
  2: 'Medium',
  3: 'High',
  4: 'Critical'
};

const nessusScanDetailsColumns = ({ id: scanId }, scanNoteObj) => [
  {
    title: 'Severity Level',
    dataIndex: 'severity',
    render: severity => SEVERITY_MAP[severity]
  },
  {
    title: 'Category',
    dataIndex: 'category'
  },
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Count',
    dataIndex: 'count'
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    render: (_, { id: vulnerabilityId }) => scanNoteObj[scanId + vulnerabilityId]?.note
  }
];

const Nessus = ({
  nessusScans,
  nessusNotes,
  loadNessusScanDetails,
  nessusScanDetailsLoading,
  nessusScanDetails
}) => {
  const [firstNessusScan] = nessusScans;
  const [selectedNessusScan, setSelectedNessusScan] = useState(firstNessusScan);
  const [selectedThreatLevel, setSelectedThreatLevel] = useState(4);

  const scanNoteObj = nessusNotes.reduce(
    (a, { scanId, vulnerabilityId, ...rest }) => ({
      ...a,
      [scanId + vulnerabilityId]: {
        scanId,
        vulnerabilityId,
        ...rest
      }
    }),
    0
  );

  useEffect(() => {
    const { id } = selectedNessusScan || {};
    if (id) loadNessusScanDetails({ variables: { id } });
  }, [selectedNessusScan]);

  return (
    <Wrapper className="page nessus">
      <DNSFilter />
      <div className="floating-section">
        <h3>Scans</h3>
        <Radio.Group
          value={selectedNessusScan?.id}
          onChange={({ target: { value } }) =>
            setSelectedNessusScan(nessusScans.find(({ id }) => id === value))
          }
          style={{ marginBottom: 16 }}
        >
          {nessusScans.map(({ id, name }) => (
            <Radio.Button key={id} value={id}>
              {name}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>

      <div className="floating-section">
        <h3>Network Vulnerability</h3>
        <div className="severity-cards">
          {Object.entries(SEVERITY_MAP)
            .reverse()
            .map(([threatInt, threatString]) => (
              <div
                className={`severity-card ${threatString.toLowerCase()}${
                  selectedThreatLevel === parseInt(threatInt) ? ' selected' : ''
                }`}
                onClick={() => setSelectedThreatLevel(parseInt(threatInt))}
                key={threatInt}
              >
                <span className="severity">{threatString}</span>
                <span className="threat-count">
                  {nessusScanDetails
                    .filter(({ severity }) => severity === parseInt(threatInt))
                    .reduce((a, { count }) => a + count, 0)}
                </span>
              </div>
            ))}
        </div>
      </div>

      <div className="floating-section">
        <h3>Report Summary</h3>
        <Table
          dataSource={nessusScanDetails.filter(
            ({ severity }) => severity === selectedThreatLevel
          )}
          columns={nessusScanDetailsColumns(selectedNessusScan || {}, scanNoteObj)}
          rowKey={({ id }) => id}
          bordered
          rowClassName="row"
          size="small"
          scroll={{ x: 'max-content' }}
          locale={{
            emptyText: 'There are no Threats found.'
          }}
          loading={nessusScanDetailsLoading}
        />
      </div>
    </Wrapper>
  );
};

export default Nessus;
