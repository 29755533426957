import React, { useState } from 'react';
import { Field } from 'formik';
import { Transfer, Form } from 'antd';

const { Item } = Form;

// Sets the color of warning: 'success', 'warning', 'error', 'validating'
const status = 'warning';

/*
  pass data through variable: dataSource = {key: "<value>", title: "<value>"}]
  pass targetKeys and setTargetKeys to retrieve values selected by transfer
*/

const AntTransfer = ({ field, form, label, targetKeys, setTargetKeys, ...rest }) => {
  // For the values that want to move from left side to right on transfer view
  const [selectedKeys, setSelectedKeys] = useState([]);
  // Handles changes to the Transfer form
  const handleChange = nextTargetKeys => setTargetKeys(nextTargetKeys);

  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const errorExistsAndFieldTouched =
    !!form.errors[field.name] && !!form.touched[field.name];
  return (
    <Item
      label={label}
      validateStatus={errorExistsAndFieldTouched ? status : null}
      help={errorExistsAndFieldTouched ? form.errors[field.name] : null}
    >
      <Transfer
        {...field}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={handleChange}
        onSelectChange={handleSelectChange}
        {...rest}
        render={item => item.title}
      />
    </Item>
  );
};

const FormikTransfer = props => <Field component={AntTransfer} {...props} />;

export default FormikTransfer;
