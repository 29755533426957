import React from 'react';
import { Drawer, Row, Col, Select, Input } from 'antd';
import { ClockCircleOutlined, PaperClipOutlined } from '@ant-design/icons';
import { timeDiffWithSeconds } from '~/lib/utils/dateUtils';
import Comments from './com/Comments';
import FixedFooter from './com/FixedFooter';
import AssignedUser from './com/AssignedUser';

import './TicketDetail.scss';

const StyledSelect = ({ label, value }) => (
  <div className={`styled-input ${label.toLowerCase()}`}>
    <span>{label}</span>
    <Select disabled defaultValue={value}>
      <Option key={value} value={value}>
        {value}
      </Option>
    </Select>
  </div>
);

const StyledInput = ({ label, value }) => (
  <div className={`styled-input ${label.toLowerCase()}`}>
    <span>{label}</span>
    <Input disabled value={value} />
  </div>
);

const { Option } = Select;

const TicketDetail = ({ ticket, setSelectedTicket }) => {
  const {
    id,
    ticketNumber,
    attachments,
    status,
    client,
    clientUser,
    user,
    createdAt,
    durationEnd,
    externalComments,
    teamId,
    category,
    priority,
    subject,
    workstation
  } = ticket || {};
  const { workstationNumber } = workstation || {};
  const { companyName, firstContact, accountNumber } = client || {};
  const { name: contactName, phone, email } = firstContact || {};
  const { name: assignedUserName, image } = user || {};
  const { name: assignedClientUserName } = clientUser || {};

  return (
    <Drawer
      title={`View Ticket - ${ticketNumber}`}
      placement="right"
      closable
      onClose={() => setSelectedTicket(false)}
      visible={!!ticket}
      width="70%"
      className="ticket-view"
      destroyOnClose
    >
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <div className="company-info">
            {companyName}
            <br />
            Account: <span className="account-number">{accountNumber}</span>
            <br />
            {contactName}
            <br />
            {email}
            <br />
            {phone}
          </div>
        </Col>
        <Col span={11}>
          <div className="ticket-info-container">
            <div className="ticket-info">
              <div className="status">
                <span
                  className={`status-circle ${(status || '')
                    .toLowerCase()
                    .split(' ')
                    .join('-')}`}
                />{' '}
                {status}
              </div>
              <div className="duration">
                <ClockCircleOutlined />
                {timeDiffWithSeconds(
                  new Date(parseInt(createdAt, 10)),
                  new Date(parseInt(durationEnd, 10))
                )}
              </div>
            </div>
            <AssignedUser
              assignedUserName={assignedUserName}
              teamId={teamId}
              image={image}
            />
          </div>
        </Col>
      </Row>

      <Row type="flex" align="middle" justify="space-between" className="ticket-details">
        <Col span={11}>
          <StyledSelect label="Category" value={category} />
          <StyledInput label="Workstation" value={workstationNumber} />
        </Col>

        <Col span={11}>
          <StyledSelect label="Priority" value={priority} />
          <StyledSelect label="Client User" value={assignedClientUserName} />
        </Col>
      </Row>

      <StyledInput label="Subject" value={subject} />

      <Comments
        ticket={ticket}
        externalComments={externalComments}
        hasClientUser={!!clientUser}
      />

      {!!attachments &&
        attachments.map(({ link }) => (
          <a
            href={link}
            key={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: 'block' }}
          >
            <PaperClipOutlined /> File Attachment
          </a>
        ))}

      <FixedFooter status={status} ticketId={id} setSelectedTicket={setSelectedTicket} />
    </Drawer>
  );
};

export default TicketDetail;
