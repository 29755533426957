import _ from 'lodash';
import { Radio, Icon } from 'antd';
import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import { Range, getTrackBackground } from 'react-range';
import './DateRangeSlider.scss';

const timeUnitOptions = ['day', 'week', 'month'];

const DateRangeSlider = ({
  from: initialValueFrom,
  to: initialValueTo,
  values,
  onChange,
  timeUnit,
  showTimeUnitRadioButtons = false,
  onTimeUnitChange,
  className = '',
  timeUnitFormats = {
    day: 'MMM D',
    week: 'MMM D',
    month: 'MMM'
  }
}) => {
  const [start, end] = values;
  const from = moment(initialValueFrom).startOf(timeUnit);
  const to = moment(initialValueTo).startOf(timeUnit);
  const min = 0;
  const max = moment(to).diff(from, timeUnit);
  const rangeValues = [
    moment(start).diff(from, timeUnit),
    max - moment(to).diff(end, timeUnit)
  ];
  const trackRef = useRef();
  const handleOnFinalChangeRef = useRef();
  const [inProgressVals, setInProgressVals] = useState(rangeValues);
  const trackInfo = useRef({
    inProgressVals,
    dragging: false,
    startPosition: 0,
    startRangeValue: 0
  });
  const getHorizontalPosition = e => {
    const { left, width } = trackRef.current.getBoundingClientRect();
    const positionInElement = e.clientX - left;
    const percent = positionInElement / width;
    return percent;
  };

  useEffect(() => {
    // to keep ref to in progress vals so they can be used when done dragging on the track in the mouse up function below
    trackInfo.current.inProgressVals = inProgressVals;
  }, [inProgressVals]);
  useEffect(() => {
    setInProgressVals(rangeValues);
  }, [timeUnit, values]);
  useEffect(() => {
    const handleMouseUp = () => {
      if (trackInfo.current.dragging) {
        handleOnFinalChangeRef.current(trackInfo.current.inProgressVals);
        trackInfo.current.dragging = false;
      }
    };

    // handle it on window cuz user may not let go of mouse until they're outside slider area
    window.addEventListener('mouseup', handleMouseUp);

    return () => window.removeEventListener('mouseup', handleMouseUp);
  }, []);

  const handleOnFinalChange = () => {
    onChange(
      inProgressVals.map(num =>
        moment(from)
          .add(num, timeUnit)
          .startOf(timeUnit)
          .toJSON()
      )
    );
  };

  handleOnFinalChangeRef.current = handleOnFinalChange;

  return (
    <div
      className={`date-range-slider ${className}`}
      onMouseMove={e => {
        // put event handler on this div rather than inside the
        // `renderTrack` below so there's more space to capture the mousemove event
        if (!trackInfo.current.dragging) return;
        const pos = getHorizontalPosition(e);
        const pctChange = pos - trackInfo.current.startPosition;
        const rangeDiff = max - min;
        const startEndDiff = rangeValues[1] - rangeValues[0];
        const change = rangeDiff * pctChange;
        const newStart = _.clamp(
          Math.round(trackInfo.current.startRangeValue + change),
          min,
          max - startEndDiff
        );
        const newValues = [newStart, newStart + startEndDiff];
        setInProgressVals(newValues);
      }}
    >
      <Range
        step={0.1} // keeping the step low ensures the slider moves faster rather than wait till user drags all the way to the next whole number
        min={min}
        max={max}
        values={inProgressVals}
        onChange={setInProgressVals}
        onFinalChange={handleOnFinalChange}
        renderTrack={({ props, children }) => (
          <div>
            <div
              ref={r => {
                if (r) {
                  // if you don't check for this a getBoundingClientRect() error could be triggered from react-range when switching to/from fullscreen
                  props.ref.current = r; // eslint-disable-line no-param-reassign
                  trackRef.current = r;
                }
              }}
              onMouseDown={e => {
                e.preventDefault(); // prevent text from being selected and such while sliding is happening
                trackInfo.current = {
                  dragging: true,
                  startPosition: getHorizontalPosition(e),
                  startRangeValue: rangeValues[0]
                };
              }}
              style={{
                ...props.style,
                cursor: 'ew-resize',
                height: '20px',
                margin: `0px 80px 0px 65px`,
                background: getTrackBackground({
                  values: inProgressVals,
                  colors: ['rgb(242,243,246)', 'rgb(203,207,212)', 'rgb(242,243,246)'],
                  min,
                  max
                })
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            className="thumb"
            style={props.style}
            onMouseDown={e => e.stopPropagation()} // so dragging track doesn't stop if dragging quickly and the thumb captures the mouse down
          >
            <div
              style={{
                ...(props.key === 0
                  ? {
                      left: '-5px',
                      transform: 'translateX(-100%)'
                    }
                  : {
                      right: '-7px',
                      transform: 'translateX(100%)'
                    }),
                whiteSpace: 'nowrap',
                position: 'absolute'
              }}
            >
              {moment(from)
                .add(inProgressVals[props.key], timeUnit)
                .startOf(timeUnit)
                .format(timeUnitFormats[timeUnit])}
            </div>
            <Icon type="more" />{' '}
          </div>
        )}
      />
      {showTimeUnitRadioButtons && (
        <Radio.Group value={timeUnit}>
          {timeUnitOptions.map(p => (
            <Radio.Button
              value={p}
              key={p}
              name={p}
              onClick={e => {
                const newTimeUnit = e.target.value;
                onTimeUnitChange(newTimeUnit, [
                  moment(initialValueFrom)
                    .startOf(newTimeUnit)
                    .toJSON(),
                  moment(initialValueTo)
                    .startOf(newTimeUnit)
                    .toJSON()
                ]);
              }}
            >
              {p.toUpperCase()}
            </Radio.Button>
          ))}
        </Radio.Group>
      )}
    </div>
  );
};

export default DateRangeSlider;
