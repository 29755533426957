import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Spinner } from '~/shared/elements';
import ApiError from '~/shared/errors/ApiError';
import Account from './Account';

const CLIENT = gql`
  {
    selfClient {
      id
      team {
        id
        name
        teamLead {
          id
          phone
          email
        }
      }
    }
  }
`;

const AccountData = props => {
  const { data, error, loading } = useQuery(CLIENT);

  if (loading) return <Spinner />;
  if (error) return <ApiError error={error} />;

  return <Account client={data.selfClient} {...props} />;
};

export default AccountData;
